/**
 * import and init global plugins
 */

import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/assets/i18n/en.json";
import vi from "@/assets/i18n/vi.json";

Vue.use(VueI18n);

export const defaultLocale = "vi";
export const languages = {
  en: en,
  vi: vi,
};

export function getLocale() {
  const lang = localStorage.getItem("lang");
  if (!lang) {
    return defaultLocale;
  }
  return lang;
}

export default new VueI18n({
  locale: getLocale(),
  fallbackLocale: defaultLocale,
  messages: Object.assign(languages),
  silentTranslationWarn: true,
});
