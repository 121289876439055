<template>
  <header
    id="home"
    :class="isCustomVideo ? 'main-header main-header-video' : 'main-header'"
  >
    <!-- Navigation -->
    <Header @changeLang="handleChangeLang" />
    <slot name="slide"></slot>
  </header>
</template>
<script>
import Header from "@/components/Header.vue";
import $ from "jquery";
export default {
  name: "Navigation",
  props: {
    isCustomVideo: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    Header,
  },
  data() {
    return {
      productList: [],
      headerPreviewBlogList: [],
      headerPreviewNewPostList: [],
      headerServiceList: [],
    };
  },
  mounted() {
    this.initAnimationHeader();
  },
  methods: {
    handleChangeLang() {
      const currentPath = window.location.pathname;
      const regexGoToHomePath =
        /(^\/service\/)|(^\/view-detail)|(^\/page\/detail)/;
      const regexNoLoadPath = /(^\/tra-man-quan)|(^\/about)/;
      if (regexNoLoadPath.test(currentPath)) {
        return;
      }
      if (regexGoToHomePath.test(currentPath)) {
        this.$router.push("/");
      } else {
        location.reload();
      }
    },
    initAnimationHeader() {
      let windows = $(window);

      if (windows.width() < 1190) {
        $(".main-nav").attr("id", "main-nav-mobile");
      }
      windows.resize(function () {
        let width = windows.width();
        if (width < 1190) {
          $(".main-nav").attr("id", "main-nav-mobile");
        } else {
          $(".main-nav").attr("id", "");
        }
      });

      // ====================== dropdown navigation ============================
      var addDropdownIcon = function () {
        $(".menu-item-has-child").each(function () {
          let currentItem = $(this).find(".nav-link");
          let currentText = currentItem.text();
          currentItem.html(
            currentText +
              '<span class="icon-dropdown"><fa-icon icon="fas fa-caret-down"></fa-icon></span>'
          );
        });
      };
      if ($(".main-nav").attr("id") === "main-nav-mobile") {
        addDropdownIcon();
      }
      windows.resize(function () {
        if (windows.width() < 1190) {
          addDropdownIcon();
        } else {
          $(".icon-dropdown").css("display", "none");
        }
      });
    },
  },
};
</script>
