<template>
  <Carousel
    class="slide-body"
    :per-page="1"
    :mouse-drag="true"
    loop
    autoplay
    :autoplay-timeout="5000"
  >
    <Slide v-for="item in reviewDataList" :key="item.index">
      <div class="review-card-container">
        <div class="review-card-avatar">
          <img :src="item.imgUrl" />
        </div>
        <div class="review-card-body">
          <div class="review-card-content">
            {{ item.title }}
          </div>
          <div class="review-card-name">{{ item.createdBy }}</div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "CustomerReview",
  components: {
    Carousel,
    Slide,
  },
  props: {
    reviewDataList: Array,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.slide-body {
  margin: 0 auto;
  text-align: -webkit-center;
}
img {
  border-radius: 50%;
  height: 128px;
  width: 128px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .review-card-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 315px;
    height: auto;
    align-items: center;
    box-shadow: 1px 1px #ccc;
    background-color: #f5f5f5;
    padding: 15px;
  }
  .review-card-body {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .review-card-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 620px;
    height: auto;
    align-items: center;
    box-shadow: 1px 1px #ccc;
    background-color: #f5f5f5;
    padding: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .review-card-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 720px;
    height: auto;
    align-items: center;
    box-shadow: 1px 1px #ccc;
    background-color: #f5f5f5;
    padding: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .review-card-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 15px;
    width: 850px;
    height: auto;
    align-items: center;
    box-shadow: 1px 1px #ccc;
    background-color: #f5f5f5;
    padding: 15px;
  }
}

.review-card-body {
  text-align: left;
  flex-grow: 1;
}

.review-card-avatar {
  width: 165px;
  min-width: 175px;
  flex-grow: 0;
}
.review-card-content {
  font-style: italic;
}

.review-card-name {
  margin-top: 35px;
}
</style>
