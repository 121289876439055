<template>
  <div id="app">
    <b-overlay class="overlay-screen" :show="isShowBottomPopup">
      <router-view />

      <div class="chat-box-icon">
        <div @click="handleOpen">
          <img src="./components/imgs/iconchat.png" width="60px" />
        </div>

        <ChatboxMessage
          :isActive="isActiveMessageBox"
          @closeChatBoxMessage="closeChatBoxMessage"
          :class="
            isOpenMessageChat ? 'active-message-box' : 'inactive-message-box'
          "
        />
        <LoginDialog ref="modalLogin" @openSignup="openSignup" />
        <RegisterFormDialog
          ref="modalSignup"
          @openLoginDialog="openLoginDialog"
        />
      </div>
      <div class="number-phone-icon">
        <div class="phone-vr">
          <div class="phone-vr-circle-fill"></div>
          <div class="phone-vr-img-circle">
            <a href="tel:0877701184"
              ><img src="./components/imgs/phone-icon.png" width="60px"
            /></a>
          </div>
        </div>
      </div>

      <div class="zalo-icon" id="zalo-icon">
        <div class="phone-vr">
          <div class="phone-vr-circle-fill"></div>
          <div class="phone-vr-img-circle">
            <a href="https://zalo.me/0877701184" target="_blank"
              ><img src="./components/imgs/zalo-icon.png" width="60px"
            /></a>
          </div>
        </div>
      </div>

      <div class="facebook-icon" id="zalo-icon">
        <div class="phone-vr">
          <div class="phone-vr-circle-fill"></div>
          <div class="phone-vr-img-circle">
            <a
              href="https://www.facebook.com/profile.php?id=100091319997195"
              target="_blank"
              ><img src="./components/imgs/facebook-icon.png" width="60px"
            /></a>
          </div>
        </div>
      </div>

      <div class="messager-icon" id="zalo-icon">
        <div class="phone-vr">
          <div class="phone-vr-circle-fill"></div>
          <div class="phone-vr-img-circle">
            <a href="https://m.me/112609578464479" target="_blank"
              ><img src="./components/imgs/messenger-icon.png" width="60px"
            /></a>
          </div>
        </div>
      </div>

      <div
        class="bottom-popup-container"
        :class="{ 'bottom-popup-container-inactive': !isShowBottomPopup }"
      >
        <div class="bottom-popup-body">
          <img
            class="bottom-popup-image"
            src="@/assets/popup_dialog_image.jpg"
            alt="Magarims Luxury Spa Logo"
          />
          <div class="bottom-popup-content">
            <div class="bottom-popup-title">
              {{ $t("title.magarims-luxury-spa") }}
            </div>
            <div class="bottom-popup-main-content">
              {{ $t("home.bottom-popup-content") }}
            </div>
          </div>
        </div>
        <div class="bottom-popup-button">
          <b-button
            class="agree-button"
            variant="primary"
            size="sm"
            @click="handleClickAgreeBtn"
          >
            {{ $t("button.agree") }}
          </b-button>
          <b-button
            class="book-now-button"
            variant="primary"
            size="sm"
            @click="handleClickBookNow"
          >
            {{ $t("button.book-now") }}
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import ChatboxMessage from "@/components/ChatboxMessage.vue";
import LoginDialog from "./components/LoginDialog.vue";
import RegisterFormDialog from "./components/RegisterFormDialog.vue";
export default {
  name: "App",
  components: {
    ChatboxMessage,
    LoginDialog,
    RegisterFormDialog,
  },
  computed: {
    isOpenMessageChat() {
      return this.isActiveMessageBox;
    },
  },
  data() {
    return {
      isActiveMessageBox: false,
      isShowBottomPopup: true,
    };
  },
  watch: {
    "$store.state.isOpenLogin"(value) {
      if (value) {
        this.$refs["modalLogin"].$refs["modal"].show();
      }
    },
  },
  created() {
    const isLogin = this.isLogin();
    this.$store.dispatch("handleChangeStatusLogin", isLogin);
  },
  mounted() {
    window.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener("keydown", function (e) {
      if (e.key == "F12") {
        e.preventDefault();
        return false;
      } else if (e.ctrlKey && e.shiftKey && e.key == "I") {
        e.preventDefault();
        return false;
      }
    });

    if (window.attachEvent) {
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        this.detectDevTool();
        window.attachEvent("onresize", this.detectDevTool);
        window.attachEvent("onmousemove", this.detectDevTool);
        window.attachEvent("onfocus", this.detectDevTool);
        window.attachEvent("onblur", this.detectDevTool);
      } else {
        setTimeout(this.argument.callee, 0);
      }
    } else {
      window.addEventListener("load", this.detectDevTool);
      window.addEventListener("resize", this.detectDevTool);
      window.addEventListener("mousemove", this.detectDevTool);
      window.addEventListener("focus", this.detectDevTool);
      window.addEventListener("blur", this.detectDevTool);
    }
  },
  methods: {
    openLoginDialog() {
      this.$refs["modalLogin"].$refs["modal"].show();
    },
    openSignup() {
      this.$refs["modalSignup"].$refs["modal"].show();
    },
    isLogin() {
      const userId = localStorage.getItem("user_id");
      if (userId) {
        return true;
      }
      return false;
    },
    closeChatBoxMessage() {
      this.isActiveMessageBox = false;
    },
    handleClickAgreeBtn() {
      this.isShowBottomPopup = false;
    },
    handleClickBookNow() {
      this.isShowBottomPopup = false;
      window.location.href = "tel:0877701184";
    },
    handleOpen() {
      if (!this.isActiveMessageBox) {
        if (!this.$store.getters.getStatusLogin) {
          this.$refs["modalLogin"].$refs["modal"].show();
          return;
        }
      }
      this.isActiveMessageBox = true;
    },
    detectDevTool() {
      const start = new Date();
      debugger;
      const end = new Date();
      if (isNaN(start) || isNaN(end) || end - start > 10) {
        window.location = "about:blank";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.inactive-message-box {
  display: none;
}
.active-message-box {
  display: block;
}
.chat-box-icon {
  position: fixed;
  right: 130px;
  bottom: 24px;
  cursor: pointer;
  z-index: 9999;
  display: none;
}
.number-phone-icon {
  position: fixed;
  left: 10px;
  bottom: 24px;
  cursor: pointer;
  z-index: 9999;
}

.facebook-icon {
  position: fixed;
  left: 10px;
  bottom: 196px;
  cursor: pointer;
  z-index: 9999;
}

.zalo-icon {
  position: fixed;
  left: 10px;
  bottom: 110px;
  cursor: pointer;
  z-index: 9999;
}

.messager-icon {
  position: fixed;
  right: 10px;
  bottom: 24px;
  cursor: pointer;
  z-index: 9999;
}

.bottom-popup-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10001;
  animation-duration: 1.2s;
  background-color: #fefefe;
  border-radius: 10px;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.2);
  width: 460px;
  height: 270px;
  padding: 15px;
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .bottom-popup-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10001;
    animation-duration: 1.2s;
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.2);
    width: 320px;
    height: 270px;
    padding: 15px;
    gap: 10px;
  }
}

.bottom-popup-container-inactive {
  display: none;
}

.bottom-popup-body {
  display: flex;
  height: 190px;
}

.bottom-popup-image {
  margin-right: 20px;
}
.bottom-popup-content {
  overflow: auto;
}

.bottom-popup-title {
  font-weight: 700;
  font-size: 20px;
}

.bottom-popup-main-content {
  font-size: 14px;
  margin-top: 10px;
}
.bottom-popup-button {
  text-align: center;
  margin-top: 10px;
}
.agree-button {
  margin-right: 15px;
}

.book-now-button {
  margin-left: 15px;
}
.phone-vr {
  position: relative;
  visibility: visible;
  background-color: transparent;
  width: 90px;
  height: 90px;
  cursor: pointer;
  z-index: 11;
  transform: visibility 0.5s;
  left: 0;
  bottom: 0;
  display: block;
}
.phone-vr-circle-fill {
  width: 65px;
  height: 65px;
  top: 12px;
  left: 12px;
  position: absolute;
  box-shadow: 0 0 0 0 #c31d1d;
  background-color: rgba(230, 8, 8, 0.7);
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 0.5s;
  transform-origin: 50% 50%;
  animation: zoom 1.3s infinite;
}

.zalo-icon .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #2196f3;
  background-color: rgba(33, 150, 243, 0.7);
}

.facebook-icon .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 rgb(24 119 242 / 65%);
  background-color: rgb(24 119 242 / 70%);
}

.messager-icon .phone-vr-circle-fill {
  box-shadow: 0 0 0 0 rgba(231, 231, 232, 0.65);
  background-color: rgba(145, 147, 149, 0.7);
}

.phone-vr-img-circle {
  background-color: #e60808;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 25px;
  left: 25px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  animation: phone-vr-circle-fill 1s infinite ease-in-out;
}

.zalo-icon .phone-vr-img-circle {
  background-color: #2196f3;
}

.facebook-icon .phone-vr-img-circle {
  background-color: #1877f2;
}

.messager-icon .phone-vr-img-circle {
  background-color: #ffff;
}

.phone-vr-img-circle a {
  display: block;
  line-height: 37px;
}
.phone-vr-img-circle img {
  max-height: 25px;
  max-width: 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  transition: opacity 1s;
  transform: translate(-50%, -50%);
}

.messager-icon .phone-vr-img-circle img {
  max-height: 35px !important;
  max-width: 35px !important;
}

@keyframes zoom {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 0 15px transparent;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes phone-vr-circle-fill {
  0% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}
</style>
