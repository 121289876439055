<template>
  <b-card
    :title="blogContent.title"
    :img-src="blogContent.imgUrl"
    img-alt="Image"
    img-top
    tag="article"
    class="mb-2 blog-card-body"
  >
    <b-card-text class="card-description">
      {{ blogContent.description }}
    </b-card-text>
    <div class="read-more-date-time">
      <a class="read-more" @click="handleDShowPageDetail">
        {{ $t("blog.read-more") }}
      </a>
      <div class="blog-card-date-time">
        <span class=""> {{ blogContent.day }} </span>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    blogContent: Object,
  },
  data() {
    return {};
  },
  methods: {
    handleDShowPageDetail() {
      this.$emit("showDetail", this.blogContent);
    },
  },
};
</script>

<style scoped>
.blog-card-body {
  max-width: 25rem;
}

.blog-card-date-time {
  margin-top: 15px;
}

.read-more:hover {
  cursor: pointer;
}

.card-body {
  height: 265px;
  display: flex;
  flex-direction: column;
}

.card-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.read-more-date-time {
  margin-top: auto;
}
</style>
