<template>
  <div class="page-detail page-wrapper">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>

    <!-- scroll top -->
    <a class="scroll-top link-text" href="#home">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <!-- <Header
      id="home"
      @changeLang="handleChangeLang"
      class="header-detail-page"
      :isHomePage="false"
    /> -->
    <Navigation />
    <b-card class="content-blog">
      <b-row>
        <b-col
          :lg="
            listBlogOther &&
            listBlogOther.length > 0 &&
            listBlogLq &&
            listBlogLq.length > 0
              ? '9'
              : '12'
          "
          sm="12"
          md="12"
        >
          <b-row>
            <b-col cols="12">
              <b-card-body v-if="pageDetail" class="card-page-detail">
                <ThumbsCarousel
                  v-if="getListImage.length > 0"
                  :listImages="getListImage"
                />
                <b-card-text class="content-detail">
                  <!-- <div v-html="pageDetail.long_desc"></div> -->
                  <PreviewEditor
                    :contentPreview="pageDetail.long_desc"
                  ></PreviewEditor>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col cols="12" v-if="listBlogOther && listBlogOther.length > 0">
              <div class="context-blog-other-2">
                <h5 class="title-blog-other-2">
                  {{ $t("title.subpage2") }}
                </h5>
              </div>
              <b-row class="blog-other">
                <b-col
                  class="col-item-blog"
                  lg="4"
                  md="4"
                  sm="6"
                  v-for="(item, index) in listBlogOther"
                  :key="index"
                >
                  <b-card
                    :title="item.title"
                    :img-src="item.imgUrl"
                    img-alt="Image"
                    img-top
                    tag="article"
                    class="item-blog-other"
                    @click="goToServiceOther(item)"
                  >
                    <b-card-text class="text-blog-other">
                      {{ item.description }}
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          lg="3"
          md="12"
          sm="12"
          v-if="listBlogLq && listBlogLq.length > 0"
        >
          <h3 class="title-blog-other">{{ $t("title.subpage") }}</h3>
          <ul>
            <li v-for="(item, index) in listBlogLq" :key="index">
              <div class="blog-other-item" @click="goToServiceOther(item)">
                <img :src="item.imgUrl" alt="image" />
                <div class="content-blog-item-other">
                  <h5 class="title-content-blog-other">
                    {{ item.title }}
                  </h5>
                  <p class="content-blog-other">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/common/Navigation.vue";
// import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ThumbsCarousel from "@/components/ThumbsCarousel.vue";
import PreviewEditor from "@/components/common/PreviewEditor.vue";
import axios from "axios";
import { PAGE_API, PAGES_API } from "@/constants/api-path";
import { PAGE_TYPE } from "@/constants/common";
import "@/scss/page-detail.scss";
import $ from "jquery";
export default {
  name: "PageDetail",
  components: {
    Navigation,
    Footer,
    ThumbsCarousel,
    PreviewEditor,
  },
  props: {},
  data() {
    return {
      pageDetail: null,
      pageId: null,
      headerServiceList: [],
      headerPreviewNewPostList: [],
      headerPreviewBlogList: [],
      headerProductList: [],
      serviceList: [],
      clientSayList: [],
      expertsSayList: [],
      productList: [],
      previewNewPostList: [],
      previewBlogList: [],
      serviceComboList: [],
      listBlogOther: [],
      countRequest: 0,
      listBlogLq: [],
      listAllService: [],
    };
  },
  computed: {
    getListImage() {
      if (
        this.pageDetail &&
        this.pageDetail.alternative_images &&
        this.pageDetail.alternative_images.length > 0
      ) {
        return this.pageDetail.alternative_images;
      }
      return [];
    },
  },
  mounted() {
    this.detectDevTool();
    this.initLoading();
    // this.initAnimationHeader();
    this.pageId = this.$route.params.page_id;
    if (this.pageId) {
      this.requestGetPageDetail();
    }

    // this.requestGetDataList(PAGE_TYPE.SERVICE_PAGE, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.SERVICE_PAGE, false, false, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.BLOG, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.NEWS, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    this.handlePageChange();
  },
  methods: {
    detectDevTool() {
      const start = new Date();
      debugger;
      const end = new Date();
      if (isNaN(start) || isNaN(end) || end - start > 10) {
        window.location = "about:blank";
      }
    },
    initAnimationCollectionBottom() {
      $(".slider-sub-blog").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });
    },
    initLoading() {
      // $("body").removeClass(".preload");
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    initAnimationHeader() {
      let windows = $(window);

      if (windows.width() < 1190) {
        $(".main-nav").attr("id", "main-nav-mobile");
      }
      windows.resize(function () {
        let width = windows.width();
        if (width < 1190) {
          $(".main-nav").attr("id", "main-nav-mobile");
        } else {
          $(".main-nav").attr("id", "");
        }
      });

      // ====================== dropdown navigation ============================
      var addDropdownIcon = function () {
        $(".menu-item-has-child").each(function () {
          let currentItem = $(this).find(".nav-link");
          let currentText = currentItem.text();
          currentItem.html(
            currentText +
              '<span class="icon-dropdown"><fa-icon icon="fas fa-caret-down"></fa-icon></span>'
          );
        });
      };
      if ($(".main-nav").attr("id") === "main-nav-mobile") {
        addDropdownIcon();
      }
      windows.resize(function () {
        if (windows.width() < 1190) {
          addDropdownIcon();
        } else {
          $(".icon-dropdown").css("display", "none");
        }
      });
    },
    handlePageChange() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleChangeLang() {
      location.reload();
    },
    convertData(element) {
      return {
        id: element.id,
        imgUrl: element.image,
        title: element.title,
        description: element.short_desc,
        slug: element.seo.slug,
      };
    },
    requestGetAllDataList(dataType, isDeleted, paging) {
      const requestBody = {
        page: paging.page,
        limit: paging.limit,
        filter: {
          is_deleted: isDeleted,
          page_type: dataType,
          lang: localStorage.getItem("lang") === "en" ? 1 : 0,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + PAGES_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.listAllService = res.data;
            this.listBlogLq = res.data
              .filter((item) => {
                return (
                  item.tags === this.pageDetail.tags &&
                  item.title !== this.pageDetail.title
                );
              })
              .map((element) => this.convertData(element));

            this.listBlogOther = res.data
              .filter((item) => {
                return (
                  item.subpage_parrent === this.pageDetail.subpage_type &&
                  item.title !== this.pageDetail.title
                );
              })
              .map((element) => this.convertData(element));
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    requestGetDataList(dataType, isDeleted, isParrentPage, paging) {
      const requestBody = {
        page: paging.page,
        limit: paging.limit,
        filter: {
          is_deleted: isDeleted,
          page_type: dataType,
          is_parent_page: isParrentPage,
          lang: localStorage.getItem("lang") === "en" ? 1 : 0,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + PAGES_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.countRequest += 1;
            this.buildDataList(dataType, res.data, isParrentPage);
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    makeNotification(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    buildDataList(dataType, data, isParrentPage) {
      const dataList = [];
      data.forEach((element, index) => {
        const dataObject = {
          id: element.id,
          imgUrl: element.image,
          title: element.title,
          description: element.short_desc,
          slug: element.seo.slug,
        };
        if (dataType === PAGE_TYPE.EXPERTS_SAY) {
          dataObject.type = this.$t("review.experts");
          dataObject.createdBy === element.created_by;
        } else if (dataType === PAGE_TYPE.CLIENT_SAY) {
          dataObject.type = this.$t("review.customer");
          dataObject.createdBy === element.created_by;
        } else if (dataType === PAGE_TYPE.BLOG || dataType === PAGE_TYPE.NEWS) {
          dataObject.day = "29";
          dataObject.month = "DEC";
        } else if (dataType === PAGE_TYPE.SERVICE_PAGE && isParrentPage) {
          dataObject.short_title = element.short_title;
          dataObject.subpage_type = element.subpage_type;
        } else if (dataType === PAGE_TYPE.SERVICE_PAGE && !isParrentPage) {
          dataObject.subpage_parrent = element.subpage_parrent;
          dataObject.shortDescription = this.buildProductShortDescription(
            element.short_desc
          );
        } else if (dataType === PAGE_TYPE.SERVICE_COMBO) {
          dataObject.description = dataObject.description.replace(
            /\n/g,
            "<br>"
          );
          if (index === 1) {
            dataObject.isCenter = true;
          }
        }
        dataList.push(dataObject);
      });

      switch (dataType) {
        case PAGE_TYPE.SERVICE_PAGE:
          if (isParrentPage) {
            this.serviceList = dataList;
          } else {
            this.productList = dataList;
          }
          break;
        case PAGE_TYPE.CLIENT_SAY:
          this.clientSayList = dataList;
          break;
        case PAGE_TYPE.EXPERTS_SAY:
          this.expertsSayList = dataList;
          break;
        case PAGE_TYPE.BLOG:
          this.previewBlogList = dataList;
          break;
        case PAGE_TYPE.NEWS:
          this.previewNewPostList = dataList;
          break;
        case PAGE_TYPE.SERVICE_COMBO:
          this.serviceComboList = dataList;
          break;
        default:
          break;
      }
    },
    requestGetPageDetail() {
      this.listBlogOther = [];
      axios
        .get(this.$config.backendApi + PAGE_API, {
          params: {
            id: this.pageId,
          },
        })
        .then((res) => {
          if (res && res.data) {
            this.pageDetail = res.data;
            this.requestGetAllDataList(res.data.page_type, false, {
              page: 1,
              limit: 100,
            });
          }
        })
        .catch((err) => {
          // this.$refs.dialog.hide();
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    goToServiceOther(item) {
      // const slug = item.slug ? item.slug : "luxury-service";
      if (window.location.pathname === `/page/detail/${item.id}`) {
        return;
      } else {
        this.$router.push(`/page/detail/${item.id}`);
      }
    },
    buildProductShortDescription(inputVal) {
      return inputVal.slice(0, 27) + " ...";
    },
  },
  watch: {
    "$route.params.page_id"(value) {
      if (value) {
        this.initLoading();
        this.pageId = value;
        if (this.pageId) {
          this.requestGetPageDetail();
        }
      }
    },
    serviceList(value) {
      if (value) {
        this.headerServiceList = value.slice(0, 3);
      }
    },
    previewNewPostList(value) {
      if (value) {
        this.headerPreviewNewPostList = value.slice(0, 3);
      }
    },
    previewBlogList(value) {
      if (value) {
        this.headerPreviewBlogList = value.slice(0, 3);
      }
    },
    productList(value) {
      if (value) {
        this.headerProductList = value;
      }
    },
    pageDetail(value) {
      if (value) {
        this.handlePageChange();
      }
    },
  },
};
</script>

<style scoped>
body {
  background-color: #937056 !important;
}
.page-detail {
  background-color: #937056;
  background-image: url("../assets/flower.svg");
  background-size: contain;
}
.content-blog {
  margin: 0 auto;
  margin-bottom: 20px;
  background-image: url("../assets/flower1.svg");
  background-size: contain;
}
.header-detail-page {
  background-color: #937056;
}
.content-detail {
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .content-blog {
    margin-top: -60vh;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .content-blog {
    margin-top: -65vh;
    width: 90%;
  }
}

@media only screen and (min-width: 820px) {
  .content-blog {
    margin-top: -58vh;
    width: 90%;
  }
}

@media only screen and (min-width: 912px) {
  .content-blog {
    margin-top: -50vh;
    width: 85%;
  }
}

@media only screen and (min-width: 1200px) {
  .content-blog {
    margin-top: -80vh;
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .title-blog-other {
    margin-top: unset !important;
  }
}
.title-blog-other {
  margin-top: 75px;
  font-size: 24px;
  font-weight: 700;
  color: #72543a;
}

.title-blog-other-2 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
  background: #fff;
  width: 230px;
}
.context-blog-other-2 {
  display: block;
  background: url(https://lavenderbychang.com/Content/v5/images/line_4.png);
  background-position: left 12px;
  background-repeat: repeat-x;
}
.blog-other-item {
  height: 80px;
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
}
.blog-other-item img {
  height: 100%;
  width: 80px;
  object-fit: cover;
  transition: transform 1s;
}
.title-content-blog-other {
  font-size: 13px;
  font-weight: 700;
}
.content-blog-other {
  font-size: 13px;
}
.content-blog-item-other {
  margin-left: 15px;
}
.blog-other-item:hover img {
  transform: scale(1.1);
}

.blog-other-item:hover p {
  color: #98865b;
}
.blog-other-item:hover h5 {
  color: #a58041;
}

.blog-other {
  justify-content: start;
  padding: 0px 5px;
  margin-bottom: 40px;
}
.item-blog-other .card-title {
  font-weight: 700;
}
.item-blog-other {
  height: 100%;
  overflow: hidden;
}
.item-blog-other {
  cursor: pointer;
}
.item-blog-other img {
  transition: transform 1s;
  padding: 5px;
}
.item-blog-other:hover img {
  transform: scale(1.1);
}
.text-blog-other {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.text-blog-other:hover {
  color: #98865b;
}
.col-item-blog {
  height: 30rem;
  margin-bottom: 20px;
}
.content-blog .card-body {
  padding: 1rem !important;
}
.card-page-detail .card-title {
  text-decoration-line: underline;
}
</style>
