<template>
  <div>
    <ckeditor
      :editor="editor"
      v-model="contentData"
      @ready="onReadyPreview"
    ></ckeditor>
  </div>
</template>

<script>
import DecoupledDocument from "@ckeditor/ckeditor5-build-decoupled-document";
export default {
  name: "PreviewEditor",
  props: {
    contentPreview: {
      default: "",
      type: String,
    },
  },
  watch: {
    contentPreview: {
      handler(value) {
        this.contentData = value;
      },
      immediate: true,
    },
  },
  data() {
    return {
      contentData: "",
      editor: DecoupledDocument,
      editorConfig: {},
    };
  },
  methods: {
    onReadyPreview(editor) {
      editor.enableReadOnlyMode("my-feature-id");
    },
  },
};
</script>
