<template>
  <div class="about-us-container" id="service">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>
    <!-- scroll top -->
    <a class="scroll-top link-text" href="#service">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <Navigation>
      <template v-slot:slide>
        <div class="slide-wrap-menu">
          <div class="slide-inner">
            <div class="slide-item-sub-page active">
              <img :src="serviceInformation.image" alt="magarims luxury spa" />
              <div class="slide-background-shadow"></div>
              <div class="slide-text">
                <h2>{{ convertToTitleCase(serviceInformation.title) }}</h2>
                <p>
                  {{ serviceInformation.short_desc }}
                </p>
                <h3>
                  <img
                    class="logo-slide"
                    src="@/components/imgs/logo-footer.png"
                    alt
                  />Natural Beauty
                </h3>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Navigation>
    <div class="about-us-detail-container editor-content">
      <PreviewEditor :contentPreview="serviceInformation.long_desc" />
    </div>
    <div
      v-if="listService !== null && listService.length !== 0"
      class="about-us-detail-container1"
    >
      <span class="service-dialog"
        >{{ $t("title.view-all-service") }}
        <fa-icon
          icon="fas fa-angles-right"
          beat
          color="#d3b673"
          class="sub-menu-icon"
        ></fa-icon
      ></span>
    </div>
    <div class="service-pop-up-container">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="4"
          v-for="(item, index) in listService"
          :key="index"
        >
          <div
            class="service-item"
            :style="{
              'background-image': 'url(' + item.thumbnail + ')',
            }"
          >
            <div class="service-item-wrap">
              <div class="opacity-div"></div>
              <h5>{{ convertToTitleCase(item.title) }}</h5>
              <b-btn
                class="view-btn"
                variant="outline"
                @click="goToPageService(item.id)"
                >{{ $t("title.view-detail") }}</b-btn
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- <div class="view-more-service">
      <span class="service-dialog view-more" @click="openViewAllService"
        >View More
        <fa-icon
          icon="fas fa-angles-right"
          beat
          color="#d3b673"
          class="sub-menu-icon"
        ></fa-icon
      ></span>
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/Footer.vue";
import $ from "jquery";
import { PAGE_API, PAGES_API } from "@/constants/api-path";
import axios from "axios";
import PreviewEditor from "@/components/common/PreviewEditor.vue";
export default {
  name: "CinicAndSpa",
  data() {
    return {
      content: "",
      serviceInformation: {},
      listService: [],
    };
  },
  components: {
    Navigation,
    Footer,
    PreviewEditor,
  },
  mounted() {
    this.initLoading();
    // this.initAnimationHeaderSlide();
  },
  watch: {
    "$route.params.service_id": {
      handler(value) {
        if (value) {
          this.initLoading();
          this.handlePageChange();
          this.initAnimationHeaderSlide();

          if (value) {
            this.getDetailService(value);
            this.getSubServices(value);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    convertToTitleCase(str) {
      if (str === null || str === undefined || str === "") {
        return "";
      }
      return str.toUpperCase();
    },
    handlePageChange() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    goToPageService(id) {
      const pageLevel = Number(this.$route.params.levelPage);
      switch (pageLevel) {
        case 1:
          this.$router.push(`/service/${id}/${pageLevel + 1}`);
          break;
        case 2:
          this.$router.push(`/view-detail/${id}`);
          break;
        default:
          this.$router.push(`/service/${id}/1`);
          break;
      }
    },
    getSubServices(parentId) {
      this.listService = [];
      const requestBody = {
        page: 1,
        limit: 1000,
        filter: {
          parent: parentId,
          lang: localStorage.getItem("lang") === "en" ? 1 : 0,
          is_deleted: false,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + PAGES_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.convertDataToServiceList(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    convertDataToServiceList(sourceData) {
      const services = sourceData.map((item) => {
        return {
          id: item.id,
          title: item.title,
          thumbnail: item.thumbnail,
        };
      });
      this.listService = services;
    },
    getDetailService(id) {
      axios
        .get(this.$config.backendApi + PAGE_API, {
          params: {
            id,
          },
        })
        .then((res) => {
          if (res && res.data) {
            this.serviceInformation = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    initLoading() {
      // $("body").removeClass(".preload");
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    openViewAllService() {
      this.$router.push("/service-all");
    },
    initAnimationHeaderSlide() {
      var addAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item-sub-page.active .slide-text");
        activeSlide.find("h2").addClass("animated fadeInDown");
        activeSlide.find("p").addClass("animated fadeInUp");
        activeSlide.find("h3").addClass("animated fadeInLeft");
      };
      var removeAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item-sub-page.active .slide-text");
        activeSlide.find("h2").removeClass("animated fadeInDown");
        activeSlide.find("p").removeClass("animated fadeInUp");
        activeSlide.find("h3").removeClass("animated fadeInLeft");
      };
      removeAnimatedForSlideText();
      setTimeout(() => {
        addAnimatedForSlideText();
      }, 100);
    },
  },
};
</script>

<style scoped>
.about-us-detail-container {
  margin-top: 30px !important;
  width: 55%;
  margin: 0 auto;
  color: #72543a;
  box-shadow: 1px 0px 9px -3px #72543a;
  /* border: 1px solid; */
  padding: 20px;
  border-radius: 20px;
}
.about-us-container {
  /* background-color: #f8e4d2; */
  background-image: url("../assets/flower1.svg");
  background-size: contain;
}
.about-us-detail-container1 {
  margin-top: 20px !important;
  width: 55%;
  margin: 0 auto;
  color: #72543a;
}
.title-about-us-detail {
  text-align: center;
}
.service-dialog {
  cursor: pointer;
}
.service-dialog:hover {
  color: #72543a;
}
.about-us-detail-container {
  height: auto;
}
.custom-menu-dialog {
  height: 80vh;
  overflow: auto;
  /* background-color: black !important; */
  background: url("@/components/imgs/pricing-section-bg.jpg");
}
.description-service {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  background-color: black;
  color: #d9bba1;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 50px;
}
.left-service,
.right-service {
  width: 40%;
  padding: 10px;
}
.right-service h1,
.left-service h1 {
  padding: 10px 0px 5px 0px;
  text-align: center;
  border-bottom: 3px solid #72543a;
  width: 80%;
  margin: 0 auto;
  font-family: cursive;
}
.content-service {
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
  font-family: cursive;
}
@media (max-width: 769px) {
  .description-service {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .left-service,
  .right-service {
    width: 90%;
  }
  .about-us-detail-container {
    box-shadow: unset !important;
    width: 95%;
  }
  .service-pop-up-container {
    width: 95% !important;
  }
}
.service-item {
  background-size: cover;
  height: 150px;
  display: flex;
  align-items: center;
  color: white !important;
  margin: 5px 0px;
  border: 1px solid;
  box-shadow: 1px 0px 9px -3px #72543a;
  border-radius: 10px;
  position: relative;
}
.service-item-wrap {
  width: 80%;
  margin: 0 auto;
}
.service-item-wrap button {
  color: white !important;
  border-color: white !important;
}
.service-item:hover button {
  background: #72543a;
}
.view-more {
  color: white;
}
.custom-menu-dialog header,
.custom-menu-dialog header button {
  color: white;
}
.view-more-service {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.service-pop-up-container {
  width: 55%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.opacity-div {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgb(91, 157, 46);
  background: linear-gradient(
    150deg,
    rgba(91, 157, 46, 0.5327380952380952) 14%,
    rgba(99, 175, 40, 0.3534663865546218) 21%,
    rgba(84, 72, 42, 0.3086484593837535) 39%,
    rgba(113, 99, 47, 0.364670868347339) 52%,
    rgba(66, 62, 122, 0) 71%,
    rgba(217, 217, 217, 0.6363795518207283) 83%,
    rgba(162, 180, 153, 0.6980042016806722) 91%
  );
  z-index: 1;
  opacity: 0.5;
  border-radius: 10px;
}
.service-item:hover .opacity-div {
  z-index: -1;
}
.view-btn {
  font-size: 12px;
}
.slide-background-shadow {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  opacity: 0.2;
}
</style>
