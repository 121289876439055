import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Home from "@/views/home/Home.vue";
import PageDetail from "@/components/PageDetail.vue";
import AboutUs from "@/components/AboutUs.vue";
import TraManQuan from "@/components/TraManQuan.vue";
// import CommonSoon from "@/components/CommonSoon.vue";
import ClinicAndSpa from "@/components/ClinicAndSpa.vue";
import ViewDetailService from "@/components/ViewDetailService.vue";
import Blogs from "@/components/Blogs.vue";
import DisplayPdfFile from "@/components/common/DisplayPdfFile.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/page/detail/:page_id",
    name: "page-detail",
    component: PageDetail,
  },
  {
    path: "/about",
    name: "about",
    component: AboutUs,
  },
  {
    path: "/tra-man-quan",
    name: "tra man quan",
    component: TraManQuan,
  },
  {
    path: "/service/:service_id/:levelPage",
    name: "Service",
    component: ClinicAndSpa,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
  },
  {
    path: "/view-detail/:service_id",
    name: "ViewDetailService",
    component: ViewDetailService,
  },
  {
    path: "/view-all-service",
    name: "ViewAllService",
    component: DisplayPdfFile,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
