<template>
  <div>
    <div class="app-header">
      <template v-if="isLoading"> Loading... </template>

      <template v-else>
        <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

        <span v-else>
          <button :disabled="page <= 1" @click="page--">❮</button>

          {{ page }} / {{ pageCount }}

          <button :disabled="page >= pageCount" @click="page++">❯</button>
        </span>

        <label class="right">
          <input v-model="showAllPages" type="checkbox" />
          Show all pages
        </label>
      </template>
    </div>
    <div class="app-content">
      <vue-pdf-embed
        :source="pdfLink"
        @rendered="handleDocumentRender"
        ref="pdfRef"
        :page="page"
      />
    </div>
  </div>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  name: "DisplayPdfFile",
  components: {
    VuePdfEmbed,
  },
  props: {
    pdfLink: {
      default: "https://magarimsluxuryspa.com/api/image/menu/magarims-menu.pdf",
      type: String,
    },
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
    };
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
  },
};
</script>
<style>
.vue-pdf-embed canvas {
  width: 60% !important;
  height: 100% !important;
  margin: 0 auto;
}
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #72543a;
  color: #ddd;
}

.app-content {
  padding: 24px 16px;
}

.right {
  float: right;
}
</style>
