export const PAGE_API = "page";
export const PAGES_API = "pages";
export const MENU_API = "menu";
export const IMAGE_LIST_API = "imagelist";
export const IMAGES_LIST_API = "imagelists";
export const USER_CONTACT_API = "user/contact";
export const LOGIN_API = "signin";
export const REGISTER_API = "signup";
export const CREATE_CHANNEL = "channel/new";
export const CHANNEL_DETAIL_API = "channel/detail?userId={0}";
export const CHANNEL_HISTORY_API = "channel/{0}/history?page=1&limit=100";
export const WS_URL =
  "wss://magarimsluxuryspa.com/api/channel/{0}/chat?userId={1}&userName={2}";
