<template>
  <div class="about-us-container" id="about-us">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>
    <!-- scroll top -->
    <a class="scroll-top link-text" href="#about-us">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <Navigation>
      <template v-slot:slide>
        <div class="slide-wrap-menu">
          <div class="slide-inner">
            <div class="slide-item-sub-page active">
              <img
                src="@/components/imgs/about_magarims.png"
                alt="magarims luxury spa"
              />
              <div class="slide-background-shadow"></div>
              <div class="slide-text">
                <h2>{{ $t("about-us.title-header") }}</h2>
                <p>
                  {{ $t("about-us.content-header") }}
                </p>
                <h3>
                  <img
                    class="logo-slide"
                    src="@/components/imgs/logo-footer.png"
                    alt
                  />Natural Beauty
                </h3>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Navigation>
    <div class="about-us-detail-container">
      <div class="title-tra-man-quan-wrap">
        <h2 class="title-about-us-detail top-header">
          {{ $t("about-us.header-detail-1") }}
        </h2>

        <h3 class="title-about-us-detail">
          {{ $t("about-us.header-detail-2") }}
        </h3>

        <h4 class="title-about-us-detail">
          {{ $t("about-us.sub-header-detail") }}
        </h4>
      </div>
      <p
        class="content"
        v-for="(item, index) in listContentDetail"
        :key="index + 'a'"
      >
        {{ $t(item) }}
      </p>
    </div>
    <div class="about-us-detail-container">
      <a href="/view-all-service" target="_blank" class="service-dialog"
        >{{ $t("title.view-all-service") }}
        <fa-icon
          icon="fas fa-angles-right"
          beat
          color="#d3b673"
          class="sub-menu-icon"
        ></fa-icon
      ></a>
    </div>
    <div class="description-service">
      <div class="left-service">
        <div class="slide-image-service">
          <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            img-width="1024"
            img-height="300"
            controls
          >
            <b-carousel-slide
              v-for="(image, index) in listImageSlideLeft"
              :key="`${index}-left`"
              :img-src="getImage(image)"
            >
            </b-carousel-slide>
          </b-carousel>
        </div>
        <h1>{{ $t("about-us.title-content-left") }}</h1>
        <div class="content-service">
          {{ $t("about-us.content-left") }}
        </div>
      </div>
      <div class="right-service">
        <div class="slide-image-service">
          <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            img-width="1024"
            img-height="300"
            controls
          >
            <b-carousel-slide
              v-for="(image, index) in listImageSlideRight"
              :key="`${index}-right`"
              :img-src="getImage(image)"
            ></b-carousel-slide>
          </b-carousel>
        </div>
        <h1>{{ $t("about-us.title-content-right") }}</h1>
        <div class="content-service">
          {{ $t("about-us.content-right") }}
        </div>
      </div>
    </div>
    <div class="galler-wrap">
      <div class="description-galler">
        <!-- Galler -->
        <h3
          class="title-galler"
          v-for="(item, index) in listHeaderGaller"
          :key="index"
        >
          {{ $t(item) }}
        </h3>
        <CarouselCollection :images="landingPageImgsBottom" />
      </div>
    </div>
    <b-modal
      :hide-footer="true"
      content-class="custom-menu-dialog"
      id="menu-dialog"
      size="xl"
    >
      <embed
        src="https://vinasupport.com/my_pdf_file.pdf"
        height="100%"
        width="100%"
        frameBorder="0"
        scrolling="auto"
        type="application/pdf"
      />
    </b-modal>
    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/Footer.vue";
import $ from "jquery";
import { IMAGES_LIST_API } from "@/constants/api-path";
import axios from "axios";
import CarouselCollection from "@/components/common/CarouselCollection.vue";
export default {
  name: "AboutUs",
  components: {
    Navigation,
    Footer,
    CarouselCollection,
  },
  data() {
    return {
      listHeaderGaller: ["about-us.collection"],
      landingPageImgsBottom: [],
      listContentDetail: [
        "about-us.content-detail-1",
        "about-us.content-detail-2",
        "about-us.content-detail-3",
        "about-us.content-detail-4",
        "about-us.content-detail-5",
        "about-us.content-detail-6",
      ],
      listHeaderDetail: [
        "about-us.header-detail-1",
        "about-us.header-detail-2",
      ],
      listImageSlideLeft: [
        "style_image_1.jpg",
        "style_image_2.jpg",
        "style_image_3.jpg",
        "style_image_4.jpg",
        "style_image_5.jpg",
        "style_image_6.jpg",
        "style_image_7.jpg",
        "style_image_8.jpg",
        "style_image_9.jpg",
      ],
      listImageSlideRight: [
        "caring_1.jpg",
        "caring_2.jpg",
        "caring_3.jpg",
        "caring_4.jpg",
        "caring_5.jpg",
        "caring_6.jpg",
      ],
    };
  },
  created() {},
  mounted() {
    this.initLoading();
    this.initAnimationHeaderSlide();
    this.requestGetImagesGaller();
  },
  computed: {},
  methods: {
    requestGetImagesGaller() {
      const requestBody = {
        page: 1,
        limit: 1000,
        filter: {
          is_deleted: false,
          type: 2,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + IMAGES_LIST_API, requestBody)
        .then(async (res) => {
          if (res && res.data) {
            this.landingPageImgsBottom = await res.data[0].images;
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    initLoading() {
      // $("body").removeClass(".preload");
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    getImage(image) {
      return require(`@/components/imgs/about_us/${image}`);
    },
    initAnimationHeaderSlide() {
      var addAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item-sub-page.active .slide-text");
        activeSlide.find("h2").addClass("animated fadeInDown");
        activeSlide.find("p").addClass("animated fadeInUp");
        activeSlide.find("h3").addClass("animated fadeInLeft");
      };
      addAnimatedForSlideText();
    },
    openMenu() {
      this.isShowMenu = true;
    },
  },
};
</script>
<style scoped>
.about-us-container {
  background-image: url("../assets/flower1.svg");
  background-size: contain;
}
.about-us-detail-container {
  margin-top: 20px !important;
  width: 65%;
  margin: 0 auto;
  color: #72543a;
}
.title-about-us-detail {
  text-align: center;
  font-family: "sans-serif";
}
.service-dialog {
  cursor: pointer;
  font-size: 18px;
  color: #72543a;
}
.service-dialog:hover {
  color: #72543a;
}
.about-us-detail-container {
  height: auto;
}
.description-service {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  background-color: black;
  color: #d9bba1;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 50px;
}
.description-galler {
  margin-top: 20px !important;
  width: 80%;
  margin: 0 auto;
  color: #72543a;
}
.title-galler {
  color: #72543a;
  text-align: center;
  font-family: "sans-serif";
  margin-bottom: 50px;
  position: relative;
  font-size: 30px;
  font-weight: 700;
}
.title-galler::after {
  position: absolute;
  left: 50%;
  margin-left: -74px;
  bottom: -35px;
  content: "";
  background: url("https://preview.hasthemes.com/plash-preview/plash/img/icon/title.png");
  width: 149px;
  height: 21px;
}
.left-service,
.right-service {
  width: 40%;
  padding: 10px;
}
.right-service h1,
.left-service h1 {
  padding: 10px 0px 5px 0px;
  text-align: center;
  border-bottom: 3px solid #72543a;
  width: 80%;
  margin: 0 auto;
  font-family: "sans-serif";
}
.content-service {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  font-family: "sans-serif";
  line-height: 35px;
}
@media (max-width: 769px) {
  .description-service {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .left-service,
  .right-service {
    width: 90%;
  }
}
.title-tra-man-quan-wrap {
  position: relative;
  margin-bottom: 60px;
  margin-top: 20px;
}
.title-tra-man-quan-wrap::after {
  position: absolute;
  left: 50%;
  margin-left: -74px;
  content: "";
  background: url("https://preview.hasthemes.com/plash-preview/plash/img/icon/title.png");
  width: 149px;
  height: 21px;
}

.content {
  text-align: justify;
  line-height: 30px;
  font-size: 18px;
}
.top-header {
  margin-bottom: 40px;
}

@media (max-width: 1023px) {
  .about-us-detail-container {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .about-us-detail-container {
    width: 90%;
  }
  .title-about-us-detail {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .about-us-detail-container {
    width: 90%;
  }
  .title-about-us-detail {
    font-size: 20px;
  }
}
.slide-background-shadow {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  opacity: 0.2;
}
</style>
