<template>
  <section class="avenue-messenger">
    <div class="menu">
      <div class="items">
        <span>
          <a href="#" title="Minimize">&mdash;</a><br />
          <a @click="handleCloseChatBox" title="End Chat">&#10005;</a>
        </span>
      </div>
      <div class="button" @click="toggleMenu">...</div>
    </div>
    <div class="agent-face">
      <div class="half">
        <img class="agent circle" src="" alt="Jesse Tino" />
      </div>
    </div>
    <div class="chat">
      <div class="chat-title">
        <h1>Quản trị viên</h1>
        <h2>Luxury</h2>
      </div>
      <div class="messages" ref="messages">
        <div class="messages-content">
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="{
              message: message.isPersonal,
              'message-personal': message.isPersonal,
            }"
          >
            <div
              :class="{
                message: !message.isPersonal,
                new: true,
                messageCommon: true,
              }"
            >
              <figure class="avatar">
                <img v-if="!message.isPersonal" src="" />
              </figure>
              <p :class="{ loading: false, textMessage: true }">
                {{ message.content }}
              </p>
            </div>

            <div class="timestamp" v-if="message.showTimestamp">
              {{ message.timestamp }}
            </div>
            <div class="checkmark-sent-delivered" v-if="message.showTimestamp">
              &#10003;
            </div>
            <div class="checkmark-read" v-if="message.showTimestamp">
              &#10003;
            </div>
          </div>
        </div>
      </div>
      <div class="message-box">
        <textarea
          v-model="messageInput"
          type="text"
          class="message-input"
          placeholder="Type message..."
        ></textarea>
        <button @click="sendMessage" type="submit" class="message-submit">
          Send
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import {
  CREATE_CHANNEL,
  CHANNEL_DETAIL_API,
  WS_URL,
  CHANNEL_HISTORY_API,
} from "@/constants/api-path";
/* eslint-disable */
export default {
  name: "ChatboxMessage",
  created() {},
  props: {
    isActive: Boolean,
  },
  watch: {
    isActive(value) {
      if (value) {
        this.reconnectionAttempts = 0;
        this.handleGetChanelIdByEmail();
      } else {
        this.messages = [];
      }
    },
  },
  data() {
    return {
      messages: [],
      messageInput: "",
      i: 0,
      channelSeleced: "",
      socketConnection: null,
      reconnectionAttempts: 0,
      maxReconnectionAttempts: 5,
      chanleId: "",
      requestNumberError: 0,
      channelIdCommon: "64bc5840dbe4d4467fbe38e2",
    };
  },
  methods: {
    handleGetChanelIdByEmail() {
      const email = localStorage.getItem("user_id");
      axios
        .get(
          this.$config.backendApi + this.formatString(CHANNEL_DETAIL_API, email)
        )
        .then(
          (res) => {
            if (res && res.data) {
              this.chanleId = res.data.id;
              this.channelSeleced = this.formatString(
                WS_URL,
                res.data.id,
                res.data.name,
                res.data.name
              );
              this.getHistoryMessageChat();
              this.startWS();
              this.requestNumberError = 0;
            }
          },
          (error) => {
            if (this.requestNumberError < 1) {
              this.handleCreateChanelByUserId(email);
              this.requestNumberError++;
            }
            console.log(error);
          }
        );
    },
    async handleCreateChanelByUserId(userId) {
      const channelNewInformation = {
        name: userId,
        created_by: userId,
      };
      axios
        .post(this.$config.backendApi + CREATE_CHANNEL, channelNewInformation)
        .then(
          (res) => {
            if (res && res.data) {
              this.notifyNewChannel(channelNewInformation);
              this.handleGetChanelIdByEmail();
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    handleCloseChatBox() {
      this.restartWebSocket();
      this.$emit("closeChatBoxMessage");
    },
    restartWebSocket() {
      if (this.socketConnection) {
        this.socketConnection.onclose = null;
        this.socketConnection.close();
        this.socketConnection = null;
      }
    },
    getHistoryMessageChat() {
      axios
        .get(
          this.$config.backendApi +
            this.formatString(CHANNEL_HISTORY_API, this.chanleId)
        )
        .then((res) => {
          if (res && res.data) {
            res.data.sort((fisrtElement, sencondElement) => {
              return (
                new Date(fisrtElement.timestamp).getTime() -
                new Date(sencondElement.timestamp).getTime()
              );
            });
            res.data.forEach((element) => {
              this.converReceiveDataToMessage(element, false);
            });
            this.scrollToBottom();
          }
        });
    },
    notifyNewChannel(channelNewInformation) {
      const senddata = {
        type: 1,
        body: "Người dùng mới gia nhập, hãy load lại trang để có thông tin của người dùng mới",
      };
      const channelSelectCommon = this.formatString(
        WS_URL,
        this.channelIdCommon,
        channelNewInformation.name,
        channelNewInformation.name
      );
      const socket = new WebSocket(channelSelectCommon);
      socket.onopen = () => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(senddata));
          socket.close();
        }
      };
    },
    startWS() {
      this.socketConnection = new WebSocket(this.channelSeleced);
      this.socketConnection.onopen = () => {
        this.reconnectionAttempts = 0;
      };
      this.socketConnection.onclose = () => {
        this.checkWS();
      };
      this.socketConnection.onerror = (error) => {
        console.log(error);
        this.socketConnection.close();
      };
      this.socketConnection.onmessage = (e) =>
        this.handleReceiveMessage(JSON.parse(e.data));
    },
    handleReceiveMessage(data) {
      this.converReceiveDataToMessage(data, true);
      this.scrollToBottom();
    },
    reconnectWithExponentialBackoff() {
      if (this.reconnectionAttempts >= this.maxReconnectionAttempts) {
        return;
      }

      const reconnectDelay = Math.pow(2, this.reconnectionAttempts) * 1000;
      setTimeout(() => {
        this.reconnectionAttempts++;
        this.startWS();
      }, reconnectDelay);
    },
    checkWS() {
      if (!this.isWebSocketOpen()) {
        this.reconnectWithExponentialBackoff();
      } else {
        console.log("WebSocket connection is still open.");
      }
    },
    converReceiveDataToMessage(data, isReaLTime) {
      if (data.name === "admin") {
        this.messages.push({
          content: data.body,
          isPersonal: false,
          showTimestamp: false,
          timestamp: isReaLTime
            ? this.getFormattedTime()
            : this.formatDate(data.timestamp),
        });
      } else {
        this.messages.push({
          content: data.body,
          isPersonal: true,
          showTimestamp: true,
          timestamp: isReaLTime
            ? this.getFormattedTime()
            : this.formatDate(data.timestamp),
        });
      }
    },
    sendMessage() {
      const msg = this.messageInput.trim();
      if (msg === "" || this.channelSeleced === "" || this.chanleId === "") {
        return;
      }
      this.checkWS();
      const senddata = {
        type: 1,
        body: msg,
      };
      this.socketConnection.send(JSON.stringify(senddata));
      this.messageInput = "";
    },
    isWebSocketOpen() {
      return (
        this.socketConnection &&
        this.socketConnection.readyState === WebSocket.OPEN
      );
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messages;
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      });
    },
    getFormattedTime() {
      const date = new Date();
      const year = date.getFullYear().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    formatString(str, ...args) {
      return str.replace(/{(\d+)}/g, (match, index) => args[index] || "");
    },
    toggleMenu() {
      const menuItems = document.querySelector(".menu .items span");
      const menuButton = document.querySelector(".menu .button");
      menuItems.classList.toggle("active");
      menuButton.classList.toggle("active");
    },
  },
};
</script>

<style scoped lang="scss">
/*--------------------
Mixins
--------------------*/
.textMessage {
  word-wrap: break-word;
}

.messageCommon {
  max-width: 85%;
}

@mixin center {
  position: relative;
}

@mixin ball {
  @include center;
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #888;
  z-index: 2;
  margin-top: 4px;
  animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
}

/*--------------------
Body
--------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background: linear-gradient(135deg, #044f48, #345093);
  background-size: cover;
  background: #fff;
  font-family: "Avenir", "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.3;
  overflow: hidden;
}

/*--------------------
Chat
--------------------*/

.chat {
  @include center;
  width: 100%;
  height: calc(100% - 15px);
  max-height: 500px;
  z-index: 10;
  overflow: hidden;
  background: transparent;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/*--------------------
Chat Title
--------------------*/

.chat-title {
  flex: 0 1 45px;
  position: relative;
  z-index: 2;
  width: 100%;
  border-bottom: 1px solid #ccc;
  color: #777;
  padding-top: 50px;
  padding-bottom: 5px;
  background-color: #fff;
  text-transform: uppercase;
  text-align: center;

  h1,
  h2 {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 11px;
    letter-spacing: 1px;
  }

  .avatar {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 9px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 1px solid #fff;

    img {
      width: 100%;
      height: auto;
    }
  }
}

/*--------------------
Messages
--------------------*/

.messages {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  & .messages-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 101%;
    width: 100%;
  }

  .message {
    clear: both;
    float: left;
    padding: 6px 10px 7px;
    -webkit-border-radius: 20px 20px 20px 0;
    -moz-border-radius: 20px 20px 20px 0;
    border-radius: 20px 20px 20px 0;
    background: #eee;
    /*rgba(0, 0, 0, 0.1);*/
    margin: 8px 0;
    font-size: 14px;
    line-height: 1.4;
    margin-left: 35px;
    position: relative;
    border: 1px solid #ccc;

    .timestamp {
      position: absolute;
      bottom: -15px;
      font-size: 10px;
      color: #555;
      right: 30px;
    }

    .checkmark-sent-delivered {
      position: absolute;
      bottom: -15px;
      right: 10px;
      font-size: 12px;
      color: #999;
    }

    .checkmark-read {
      color: blue;
      position: absolute;
      bottom: -15px;
      right: 16px;
      font-size: 12px;
    }

    .avatar {
      position: absolute;
      z-index: 1;
      bottom: -15px;
      left: -35px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      overflow: hidden;
      margin: 0;
      padding: 0;
      border: 2px solid rgba(255, 255, 255, 0.5);

      img {
        width: 100%;
        height: auto;
      }
    }

    &.message-personal {
      max-width: 100%;
      min-width: 40%;
      float: right;
      text-align: left;
      background: #fff;
      border: 1px solid #ccc;
      -webkit-border-radius: 20px 20px 0 20px;
      -moz-border-radius: 20px 20px 0 20px;
      border-radius: 20px 20px 0 20px;
    }

    &:last-child {
      margin-bottom: 30px;
    }

    &.new {
      transform: scale(0);
      transform-origin: 0 0;
      animation: bounce 500ms linear both;
    }
  }
  .loading {
    &::before {
      @include ball;
      border: none;
      animation-delay: 0.15s;
    }
    & span {
      display: block;
      font-size: 0;
      width: 20px;
      height: 10px;
      position: relative;
      &::before {
        @include ball;
        margin-left: -7px;
      }
      &::after {
        @include ball;
        margin-left: 7px;
        animation-delay: 0.3s;
      }
    }
  }
}

/*--------------------
Message Box
--------------------*/

.message-box {
  flex: 0 1 42px;
  width: 90%;
  background: #fff;
  margin: 2px auto;
  padding: 10px;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: 14px;
  border: 1px solid #ccc;

  & .message-input {
    background: none;
    border: none;
    outline: none !important;
    resize: none;
    font-size: 15px;
    height: 24px;
    margin: 0;
    padding-right: 20px;
    width: 265px;
    color: #444;
  }

  textarea:focus:-webkit-placeholder {
    color: transparent;
  }

  & .message-submit {
    position: absolute;
    z-index: 1;
    top: 9px;
    right: 10px;
    color: #4a90e2;
    border: none;
    /* background: #c29d5f;*/
    background: #fff;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px;
    border-radius: 5px;
    outline: none !important;
    transition: background 0.2s ease;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: #333;
    }
  }
}

/*--------------------
Custom Srollbar
--------------------*/

.mCSB_scrollTools {
  margin: 1px -3px 1px 0;
  opacity: 0;
}

.mCSB_inside > .mCSB_container {
  margin-right: 0px;
  padding: 0 10px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/*--------------------
Bounce
--------------------*/

@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes ball {
  from {
    transform: translateY(0) scaleY(0.8);
  }

  to {
    transform: translateY(-10px);
  }
}

.avenue-messenger {
  opacity: 1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  height: calc(100% - 60px) !important;
  max-height: 460px !important;
  min-height: 220px !important;
  width: 320px;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  right: 150px;
  bottom: 85px;
  margin: auto;
  z-index: 1000;
  box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
}

.avenue-messenger div.agent-face {
  position: absolute;
  left: 0;
  top: -50px;
  right: 0;
  margin: auto;
  width: 101px;
  height: 50px;
  background: transparent;
  z-index: 12;
}

.avenue-messenger div {
  font-size: 14px;
  color: #232323;
}

.close {
  display: block;
  width: 100px;
  height: 100px;
  margin: 1em auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  opacity: 0.5;
}

.close:hover {
  opacity: 0.9;
}

.circle {
  display: block;
  width: 80px;
  height: 80px;
  margin: 1em auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  border: 2px solid #fff;
}

.contact-icon .circle:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  transition: 0.2s all ease-out 0.2s;
  -webkit-transition: 0.2s all ease-out 0.2s;
  -moz-transition: 0.2s all ease-out 0.2s;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 5px;
  margin-top: -5px;
}

.arrow_box {
  position: relative;
  background: #fff;
  border: 1px solid #4a90e2;
}

.arrow_box:after,
.arrow_box:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.menu .items span {
  color: #111;
  z-index: 12;
  font-size: 14px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 40px;
  height: 86px;
  line-height: 40px;
  background: #fff;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 48px;
  opacity: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
}

.menu .button {
  font-size: 30px;

  z-index: 12;
  text-align: right;
  color: #333;
  content: "...";
  display: block;
  width: 48px;
  line-height: 25px;
  height: 40px;
  border-top-right-radius: 20px;
  position: absolute;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
}

.menu .button.active {
  background: #ccc;
}

.menu .items span.active {
  opacity: 1;
}

.menu .items a {
  color: #111;
  text-decoration: none;
}

.menu .items a:hover {
  color: #777;
}

@media only screen and (max-device-width: 667px),
  screen and (max-width: 450px) {
  .avenue-messenger {
    z-index: 2147483001 !important;
    width: 100% !important;
    height: 100% !important;
    max-height: none !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    background: #fff;
  }

  .avenue-messenger div.agent-face {
    top: -10px !important;
  }

  .chat {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    max-height: initial !important;
  }

  .chat-title {
    padding: 20px 20px 15px 10px !important;
    text-align: left;
  }

  .circle {
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
  }

  .menu .button {
    border-top-right-radius: 0;
  }
}

@media only screen and (min-device-width: 667px) {
  div.half {
    margin: auto;
    width: 80px;
    height: 40px;
    background-color: #fff;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;

    border-bottom: 0;
    box-shadow: 1px 4px 20px rgba(22, 20, 19, 0.6);
    -webkit-box-shadow: 1px 4px 20px rgba(22, 20, 19, 0.6);
    -moz-box-shadow: 1px 4px 20px rgba(22, 20, 19, 0.6);
  }
}
</style>
