var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"avenue-messenger"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"items"},[_c('span',[_c('a',{attrs:{"href":"#","title":"Minimize"}},[_vm._v("—")]),_c('br'),_c('a',{attrs:{"title":"End Chat"},on:{"click":_vm.handleCloseChatBox}},[_vm._v("✕")])])]),_c('div',{staticClass:"button",on:{"click":_vm.toggleMenu}},[_vm._v("...")])]),_vm._m(0),_c('div',{staticClass:"chat"},[_vm._m(1),_c('div',{ref:"messages",staticClass:"messages"},[_c('div',{staticClass:"messages-content"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,class:{
            message: message.isPersonal,
            'message-personal': message.isPersonal,
          }},[_c('div',{class:{
              message: !message.isPersonal,
              new: true,
              messageCommon: true,
            }},[_c('figure',{staticClass:"avatar"},[(!message.isPersonal)?_c('img',{attrs:{"src":""}}):_vm._e()]),_c('p',{class:{ loading: false, textMessage: true }},[_vm._v(" "+_vm._s(message.content)+" ")])]),(message.showTimestamp)?_c('div',{staticClass:"timestamp"},[_vm._v(" "+_vm._s(message.timestamp)+" ")]):_vm._e(),(message.showTimestamp)?_c('div',{staticClass:"checkmark-sent-delivered"},[_vm._v(" ✓ ")]):_vm._e(),(message.showTimestamp)?_c('div',{staticClass:"checkmark-read"},[_vm._v(" ✓ ")]):_vm._e()])}),0)]),_c('div',{staticClass:"message-box"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageInput),expression:"messageInput"}],staticClass:"message-input",attrs:{"type":"text","placeholder":"Type message..."},domProps:{"value":(_vm.messageInput)},on:{"input":function($event){if($event.target.composing)return;_vm.messageInput=$event.target.value}}}),_c('button',{staticClass:"message-submit",attrs:{"type":"submit"},on:{"click":_vm.sendMessage}},[_vm._v(" Send ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agent-face"},[_c('div',{staticClass:"half"},[_c('img',{staticClass:"agent circle",attrs:{"src":"","alt":"Jesse Tino"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-title"},[_c('h1',[_vm._v("Quản trị viên")]),_c('h2',[_vm._v("Luxury")])])
}]

export { render, staticRenderFns }