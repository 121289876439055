var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-detail page-wrapper"},[_vm._m(0),_c('a',{staticClass:"scroll-top link-text",attrs:{"href":"#home"}},[_c('fa-icon',{attrs:{"icon":"fas fa-angles-right","beat":""}})],1),_c('Navigation'),_c('b-card',{staticClass:"content-blog"},[_c('b-row',[_c('b-col',{attrs:{"lg":_vm.listBlogOther &&
          _vm.listBlogOther.length > 0 &&
          _vm.listBlogLq &&
          _vm.listBlogLq.length > 0
            ? '9'
            : '12',"sm":"12","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.pageDetail)?_c('b-card-body',{staticClass:"card-page-detail"},[(_vm.getListImage.length > 0)?_c('ThumbsCarousel',{attrs:{"listImages":_vm.getListImage}}):_vm._e(),_c('b-card-text',{staticClass:"content-detail"},[_c('PreviewEditor',{attrs:{"contentPreview":_vm.pageDetail.long_desc}})],1)],1):_vm._e()],1),(_vm.listBlogOther && _vm.listBlogOther.length > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"context-blog-other-2"},[_c('h5',{staticClass:"title-blog-other-2"},[_vm._v(" "+_vm._s(_vm.$t("title.subpage2"))+" ")])]),_c('b-row',{staticClass:"blog-other"},_vm._l((_vm.listBlogOther),function(item,index){return _c('b-col',{key:index,staticClass:"col-item-blog",attrs:{"lg":"4","md":"4","sm":"6"}},[_c('b-card',{staticClass:"item-blog-other",attrs:{"title":item.title,"img-src":item.imgUrl,"img-alt":"Image","img-top":"","tag":"article"},on:{"click":function($event){return _vm.goToServiceOther(item)}}},[_c('b-card-text',{staticClass:"text-blog-other"},[_vm._v(" "+_vm._s(item.description)+" ")])],1)],1)}),1)],1):_vm._e()],1)],1),(_vm.listBlogLq && _vm.listBlogLq.length > 0)?_c('b-col',{attrs:{"lg":"3","md":"12","sm":"12"}},[_c('h3',{staticClass:"title-blog-other"},[_vm._v(_vm._s(_vm.$t("title.subpage")))]),_c('ul',_vm._l((_vm.listBlogLq),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"blog-other-item",on:{"click":function($event){return _vm.goToServiceOther(item)}}},[_c('img',{attrs:{"src":item.imgUrl,"alt":"image"}}),_c('div',{staticClass:"content-blog-item-other"},[_c('h5',{staticClass:"title-content-blog-other"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{staticClass:"content-blog-other"},[_vm._v(" "+_vm._s(item.description)+" ")])])])])}),0)]):_vm._e()],1)],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader",attrs:{"id":"loader"}},[_c('div',{staticClass:"loader-inner"},[_c('img',{staticClass:"loader-img",attrs:{"src":require("./imgs/loader.svg"),"alt":"Loader"}})])])
}]

export { render, staticRenderFns }