<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      controls
      :interval="0"
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
    >
      <!-- Text slides with image -->

      <b-carousel-slide v-for="(imageUrl, index) in listImages" :key="index">
        <template #img>
          <div class="image-carousel-slide">
            <img
              class="d-block w-100 resize-image"
              width="1024"
              height="480"
              :src="imageUrl"
              alt="image slot"
            />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "ThumbsCarousel",
  props: {
    listImages: Array,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
};
</script>
<style scoped>
.resize-image {
  object-fit: cover;
}
.image-carousel-slide {
  position: relative;
  cursor: pointer;
}
.delete-image-carousel {
  position: absolute;
}
</style>
