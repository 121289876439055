<template>
  <div>
    <!-- Navigation -->
    <span
      class="login-information"
      @click="handleLogout"
      v-if="$store.state.isLogin"
      >{{ userName }}
      <fa-icon
        class="icon-custom"
        icon="fas fa-right-from-bracket"
        color="while"
      ></fa-icon>
    </span>
    <!-- <span class="login-button-information" v-else @click="handleLogin"
      >Login
      <fa-icon
        class="icon-custom"
        icon="fas fa-right-to-bracket"
        color="while"
      ></fa-icon
    ></span> -->
    <span class="changle-lang">
      <div
        v-if="isLangVI && isHomePage"
        class="multi-lang-button"
        @click="handleChangeLanguage"
      >
        <b-icon icon="caret-down" aria-hidden="true"></b-icon>
        EN
      </div>

      <div
        v-if="!isLangVI && isHomePage"
        class="multi-lang-button"
        @click="handleChangeLanguage"
      >
        <b-icon icon="caret-down" aria-hidden="true"></b-icon>
        VI
      </div>
    </span>
    <nav class="navbar navbar-expand-xl navbar-dark">
      <!-- logo site -->
      <!-- <a
        class="navbar-brand link-custom disable-menu"
        rel="home"
        title="Magarims Luxury Spa"
        @click="goToHome"
      >
        <img
          src="./imgs/logo-magarims-luxury-spa.png"
          alt="Magarims Luxury Spa Logo"
          width="150"
          height="150"
        />
      </a> -->
      <!--  main nav -->

      <button
        class="navbar-toggler mobile-nav-icon"
        type="button"
        data-toggle="collapse"
        data-target="#main-nav-mobile"
      >
        <!-- <span class="navbar-toggler-icon"></span> -->
        <span>
          <fa-icon
            class="icon-custom"
            icon="fas fa-solid fa-bars"
            color="black"
          ></fa-icon>
        </span>
      </button>
      <div class="collapse navbar-collapse main-nav">
        <ul class="navbar-nav menu" id="mainNav">
          <!-- HOME TAB -->
          <li
            class="nav-item menu-item menu-item-current menu-item-has-child link-custom"
            @click="goToHome"
          >
            <div class="logo-home">
              <!-- <img
                src="./imgs/logo-magarims-luxury-spa.png"
                alt="Magarims Luxury Spa Logo"
                width="50"
                height="50"
                class="logo-image"
              /> -->
              <a class="nav-link nav-item-level-first">{{
                $t("title.home")
              }}</a>
            </div>
          </li>

          <li
            class="nav-item menu-item"
            v-for="(submenuLv1, index1) in menu"
            :key="index1"
          >
            <a
              v-if="submenuLv1.isLocalLanguage"
              class="nav-link link-custom nav-item-level-first"
              :href="submenuLv1.link"
              @click="goToPageByPath(submenuLv1.link)"
              >{{ $t(`menu.${submenuLv1.title}`) }}
            </a>
            <a
              v-else
              class="nav-link link-custom nav-item-level-first"
              @click="goToServicePageParent(submenuLv1.id, 1)"
            >
              <b-icon
                v-if="submenuLv1.submenu.length > 0"
                class="icon-down"
                icon="caret-down"
                aria-hidden="true"
              ></b-icon>
              <b-icon
                v-if="submenuLv1.submenu.length > 0"
                class="icon-up"
                icon="caret-down-fill"
                aria-hidden="true"
              ></b-icon>
              {{ submenuLv1.title }}
            </a>
            <div class="dropdown-menu" v-if="submenuLv1.submenu.length > 0">
              <div class="arrow-up"></div>
              <ul class="sub-menu-service">
                <li
                  class="submenu-item item-menu-level-1"
                  v-for="(submenuLv2, index2) in submenuLv1.submenu"
                  :key="index2"
                >
                  <a
                    v-if="submenuLv2.isLocalLanguage"
                    class="submenu-link"
                    @click="goToPageByPath(submenuLv2.link)"
                  >
                    <span>{{ $t(`menu.${submenuLv2.title}`) }}</span>
                    <fa-icon
                      icon="fas fa-angles-right"
                      beat
                      color="#d3b673"
                      class="sub-menu-icon"
                    ></fa-icon>
                  </a>
                  <a
                    v-else
                    class="submenu-link"
                    @click="goToServicePageParent(submenuLv2.id, 2)"
                  >
                    <span>{{ submenuLv2.title }}</span>
                    <fa-icon
                      icon="fas fa-angles-right"
                      beat
                      color="#d3b673"
                      class="sub-menu-icon"
                    ></fa-icon>
                  </a>
                  <div
                    class="submenu-level-2"
                    v-if="submenuLv2.submenu.length > 0"
                  >
                    <ul>
                      <li
                        class="submenu-item item-menu-level-2"
                        v-for="(submenuLv3, index3) in submenuLv2.submenu"
                        :key="index3"
                        @click="goToServicePageChild(submenuLv3.id)"
                      >
                        <a
                          v-if="submenuLv3.isLocalLanguage"
                          class="submenu-link-level-2"
                        >
                          <span>{{ $t(`menu.${submenuLv3.title}`) }}</span>
                          <fa-icon
                            icon="fas fa-link"
                            beat
                            color="#d3b673"
                            class="sub-menu-icon"
                          ></fa-icon>
                        </a>
                        <a v-else class="submenu-link-level-2">
                          <span>{{
                            convertToTitleCase(submenuLv3.title)
                          }}</span>
                          <fa-icon
                            icon="fas fa-link"
                            beat
                            color="#d3b673"
                            class="sub-menu-icon"
                          ></fa-icon>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              <!-- default menu -->
            </div>
          </li>
        </ul>
        <div class="social-media-wrap">
          <div class="footer-line social">
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100091319997195"
              class="icon-mxh"
            >
              <fa-icon
                icon="fab fa-facebook-square"
                beat
                color="#d3b673"
              ></fa-icon>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/Magarimsluxuryspa/?fbclid=IwAR0HQo1C5PTBoKIPuOPOLpA137JG7QG3MT7JL8FaQHfDgy0M9P6xnYzHHms"
              class="icon-mxh"
            >
              <fa-icon icon="fab fa-instagram" beat color="#d3b673"></fa-icon>
            </a>
          </div>
        </div>
        <div
          v-if="isLangVI && isHomePage"
          class="multi-lang-button lang-pc"
          @click="handleChangeLanguage"
        >
          <b-icon
            class="icon-down"
            icon="caret-down"
            aria-hidden="true"
          ></b-icon>
          <b-icon
            class="icon-up"
            icon="caret-down-fill"
            aria-hidden="true"
          ></b-icon>
          EN
        </div>

        <div
          v-if="!isLangVI && isHomePage"
          class="multi-lang-button lang-pc"
          @click="handleChangeLanguage"
        >
          <b-icon
            class="icon-down"
            icon="caret-down"
            aria-hidden="true"
          ></b-icon>
          <b-icon
            class="icon-up"
            icon="caret-down-fill"
            aria-hidden="true"
          ></b-icon>
          VI
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
export default {
  name: "Header",
  components: {},
  props: {
    isHomePage: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isLangVI: true,
      userName: "",
      menu: [
        {
          title: "about-us",
          link: "/about",
          isLocalLanguage: true,
          submenu: [],
        },
        {
          title: "tra-man-quan",
          link: "/tra-man-quan",
          isLocalLanguage: true,
          submenu: [],
        },
        {
          title: "blogs",
          isLocalLanguage: true,
          link: "/blogs",
          submenu: [],
        },
        {
          title: "contact-us",
          link: "/#contactUs",
          isLocalLanguage: true,
          submenu: [],
        },
      ],
      localmenu: [
        {
          title: "about-us",
          link: "/about",
          isLocalLanguage: true,
          submenu: [],
        },
        {
          title: "tra-man-quan",
          link: "/tra-man-quan",
          isLocalLanguage: true,
          submenu: [],
        },
      ],
    };
  },
  created() {
    this.initLang();
    this.getListMenuService();
    this.getLoginInformation();
  },
  mounted() {},
  watch: {
    "$store.state.isLogin"(value) {
      console.log(value);
      this.getLoginInformation();
    },
  },
  methods: {
    convertToTitleCase(str) {
      if (str === null || str === "" || str === undefined) {
        return "";
      }
      str = str.toLowerCase();
      let words = str.split(" ");
      for (let i = 0; i < words.length; i++) {
        if (words[i].charAt(0) === "(" && words[i].charAt(1) !== undefined) {
          words[i] =
            words[i].charAt(0) +
            words[i].charAt(1).toUpperCase() +
            words[i].slice(2);
        } else {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
      }
      return words.join(" ");
    },
    getListMenuService() {
      const lang = this.isLangVI ? 0 : 1;
      axios
        .get(this.$config.backendApi + "/menu?page_type=0&lang=" + lang)
        .then((res) => {
          if (res && res.data) {
            this.megerMenuData(res.data);
          }
        });
    },
    megerMenuData(listSourceData) {
      this.menu = [...this.localmenu];
      const serverMenu = this.convertDataServerToMenu(listSourceData);
      serverMenu.forEach((element) => {
        this.menu.push(element);
      });

      const blogMenu = {
        title: "blogs",
        link: "/blogs",
        isLocalLanguage: true,
        submenu: [],
      };
      const contractUsMenu = {
        title: "contact-us",
        link: "/#contactUs",
        isLocalLanguage: true,
        submenu: [],
      };
      this.menu.push(blogMenu);
      this.menu.push(contractUsMenu);
    },
    convertDataServerToMenu(listSourceData) {
      let copyData = [...listSourceData];
      if (copyData.length === 0) {
        return;
      }
      const newData = copyData.map((element) => {
        let tempData = {};
        tempData.title = element.title;
        tempData.id = element.id;
        tempData.serviceOrder = element.service_order;
        tempData.isLocalLanguage = false;
        const childsElement = this.convertDataServerToMenu(element.childs);
        tempData.submenu = childsElement ? childsElement : [];
        return tempData;
      });
      if (newData.length > 0) {
        newData.sort((a, b) => {
          return a.serviceOrder - b.serviceOrder;
        });
      }
      return newData;
    },
    moveScroll() {
      const scrollContainer =
        document.getElementsByClassName("sub-menu-service")[2];
      const currentTop = scrollContainer.scrollTop;
      scrollContainer.scrollTop = currentTop + 100;
      console.log(scrollContainer.scrollTop);
    },
    coverTitleToLowCase(title) {
      const firstCharacter = title.charAt(0).toUpperCase();
      const subString = title.substring(1).toLowerCase();
      return firstCharacter + subString;
    },
    getLoginInformation() {
      if (this.$store.state.isLogin) {
        this.userName = localStorage.getItem("user_id");
      } else {
        this.userName = "";
      }
    },
    goToPageByPath(path) {
      if (path && window.location.pathname !== path) {
        this.$router.push(path);
      }
    },
    goToServicePageParent(id, levelPage) {
      const path = `/service/${id}/${levelPage}`;
      if (path && window.location.pathname !== path) {
        this.$router.push(path);
      }
    },
    goToServicePageChild(id) {
      const path = `/view-detail/${id}`;
      if (path && window.location.pathname !== path) {
        this.$router.push(path);
      }
    },
    goToHome() {
      if (this.$route.name !== "home") {
        this.$router.push(`/`);
      }
    },
    goToServiceOther() {
      if (this.$route.name !== "home") {
        this.$router.push(`/#services`);
      }
    },
    goToContact() {
      if (this.$route.name !== "home") {
        this.$router.push(`/#contactUs`);
      }
    },
    goToLatestBlog() {
      if (this.$route.name !== "home") {
        this.$router.push(`/#latestBlog`);
      }
    },
    handleLogin() {
      this.$store.dispatch("handleChangeStatusOpenLogin", true);
    },
    handleLogout() {
      localStorage.removeItem("token");
      localStorage.removeItem("refesh_token");
      localStorage.removeItem("user_id");
      this.$store.dispatch("handleChangeStatusLogin", false);
    },
    initLang() {
      const lang = localStorage.getItem("lang");
      if (!lang || lang === "vi") {
        this.isLangVI = true;
      } else if (lang === "en") {
        this.isLangVI = false;
      }
    },
    destroyAnimation() {
      $(".slick-slide-cite-experts").slick("unslick");
      $(".slick-slide-figure-img-experts").slick("unslick");
      $(".slick-slide-figure-info-experts").slick("unslick");
      $(".slick-slide-cite-customer").slick("unslick");
      $(".slick-slide-figure-img-customer").slick("unslick");
      $(".slick-slide-figure-info-customer").slick("unslick");
      $(".news-slider").slick("unslick");
      $(".post-slider").slick("unslick");
    },
    handleChangeLanguage() {
      this.isLangVI = !this.isLangVI;
      const lang = this.isLangVI ? "vi" : "en";
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
      this.$emit("changeLang");
      this.getListMenuService();
    },
  },
};
</script>

<style scoped>
.link-custom {
  cursor: pointer;
}
.login-button {
  cursor: pointer;
}
.disable-menu {
  display: none;
}
.nav-item-level-first {
  text-transform: uppercase;
  font-weight: 500;
}
@media (min-width: 991px) {
  .nav-item-level-first {
    font-size: 18px !important;
  }
  .dropdown-menu {
    width: auto;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    padding: 0;
    border-radius: 0;
    /* min-height: 160px; */
    /* border: 1px solid #c1a1d6; */
    display: none;
    right: -70%;
    left: initial;
    padding: 5px 0px;
  }

  .submenu-level-2 {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    display: none;
    position: absolute;
    height: auto;
    left: 347px;
    top: 0px;
    width: 400px;
    overflow: auto;
    padding: 5px 0px;
  }

  .menu-service {
    right: -300%;
  }
}

@media (min-width: 1200px) {
  .nav-item:hover .dropdown-menu {
    display: block !important;
  }
  .social-media-wrap {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .changle-lang {
    display: block !important;
  }
  .lang-pc {
    display: none !important;
  }
  .login-button-information {
    right: 80px !important;
  }
  .logo-image {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .navbar {
    position: fixed !important;
  }
  #main-nav-mobile {
    width: 100vw;
  }
}

@media (min-width: 769px) {
  .dropdown-menu {
    top: 80%;
  }
}

.submenu-item {
  display: flex;
}

.submenu-link,
.submenu-link-level-2 {
  flex: 0 0 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  font-weight: 500;
  color: #72543a;
  /* text-transform: uppercase; */
  font-size: 14px;
}

.submenu-link span,
.submenu-link-level-2 span {
  align-self: center;
}

.submenu-link .sub-menu-icon,
.submenu-link-level-2 .sub-menu-icon {
  align-self: center;
}

.submenu-item .submenu-link:hover,
.submenu-item .submenu-link:focus {
  background: #72543a;
  color: #fff;
  cursor: pointer;
}

.item-menu-level-1:hover .submenu-link,
.item-menu-level-1:focus .submenu-link,
.item-menu-level-2:hover .submenu-link-level-2,
.item-menu-level-2:focus .submenu-link-level-2 {
  background: #72543a;
  color: #fff;
  cursor: pointer;
}

.logo-home .link-custom:hover,
.logo-home .link-custom:focus {
  cursor: pointer;
}

.submenu-link:hover + .submenu-ct-detail {
  display: block;
  z-index: 10;
}
.login-information {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  z-index: 1000;
  cursor: pointer;
}

.login-button-information {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  z-index: 1000;
  cursor: pointer;
  font-weight: 500;
}
.changle-lang {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  z-index: 1000;
  cursor: pointer;
  font-weight: 500;
}
.lang-pc {
  display: block;
}
.logo-home {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.logo-home a {
  font-size: 18px;
  color: white !important;
}

.item-menu-level-1 {
  width: 345px;
  height: 35px;
}
.item-menu-level-2 {
  width: 100%;
  height: 35px;
}
.item-menu-level-1:hover .submenu-level-2 {
  display: block;
  background-color: white;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  position: absolute;
  left: 48%;
  top: -20px;
}
.icon-mxh {
  font-size: 20px;
  padding: 5px;
}

.social-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: black;
}
.icon-up,
.icon-down {
  font-size: 18px !important;
}
.icon-up {
  display: none !important;
}
.menu-item:hover .icon-down,
.lang-pc:hover .icon-down {
  display: none !important;
}

.menu-item:hover .icon-up,
.lang-pc:hover .icon-up {
  display: inline-block !important;
}
</style>
