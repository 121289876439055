<template>
  <body class="preload">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>

    <!-- overlay for focus -->
    <!-- <div id="overlay" style="display: none"></div> -->

    <!-- scroll top -->
    <a class="scroll-top link-text" href="#home">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <!-- page wrapper -->
    <div class="page-wrapper">
      <!-- Header -->
      <!-- <header id="home" class="main-header"> -->
      <Navigation :isCustomVideo="true">
        <template v-slot:slide>
          <div class="active video-frame">
            <video ref="video" loop autoplay controls></video>
            <div id="background-image-video" class="image-background-video">
              <div class="play-btn" @click="playingVideo">
                <fa-icon icon="fas fa-play"></fa-icon>
              </div>
            </div>
            <div id="play-video-button" class="play-btn" @click="playingVideo">
              <fa-icon icon="fas fa-play"></fa-icon>
            </div>
          </div>
        </template>
      </Navigation>
      <!-- Navigation -->
      <!-- <Header
          @changeLang="handleChangeLang"
          :headerServiceList="headerServiceList"
          :productList="productList"
        />
        <div class="main-video">
          <video controls loop>
            <source src="@/assets/video/sample_video.mp4" type="video/mp4" />
          </video>
        </div> -->
      <!-- slides -->
      <!-- <div class="slide-wrap">
          <div class="slide-inner">
            <div class="slide-item active">
              <img :src="landingPageImgs[0]" alt="magarims luxury spa" />
              <div class="slide-text">
                <h3>{{ $t("home.welcome-spa-first") }}</h3>
                <h1>{{ $t("home.be-your-best-first") }}</h1>
                <p>
                  {{ $t("home.intro-first") }}
                </p>
                <button class="btn book-now-btn" @click="handleClickBookNowBtn">
                  {{ $t("button.book-now") }}
                </button>
              </div>
            </div>
            <div class="slide-item">
              <img :src="landingPageImgs[1]" alt="magarims luxury spa" />
              <div class="slide-text" style="text-align: left">
                <h3>{{ $t("home.welcome-spa-second") }}</h3>
                <h1>{{ $t("home.be-your-best-second") }}</h1>
                <p>
                  {{ $t("home.intro-second") }}
                </p>
                <button class="btn book-now-btn" @click="handleClickBookNowBtn">
                  {{ $t("button.book-now") }}
                </button>
              </div>
            </div>
            <div class="slide-item">
              <img :src="landingPageImgs[2]" alt="magarims luxury spa" />
              <div class="slide-text" style="text-align: right">
                <h3>{{ $t("home.welcome-spa-third") }}</h3>
                <h1>{{ $t("home.be-your-best-third") }}</h1>
                <p>
                  {{ $t("home.intro-third") }}
                </p>
                <button class="btn book-now-btn" @click="handleClickBookNowBtn">
                  {{ $t("button.book-now") }}
                </button>
              </div>
            </div>
          </div>
          <a href="#" class="slide-control" id="prev-slide"></a>
          <a href="#" class="slide-control" id="next-slide"></a>
          <ol class="slide-dot">
            <li class="active"></li>
            <li></li>
            <li></li>
          </ol>
        </div> -->
      <!-- </header> -->
      <!-- Main Story -->
      <div class="main-story">
        <div class="main-story-title-1">
          {{ $t("story.title-1") }}
        </div>
        <p><br /></p>
        <div class="main-story-title-2">
          {{ $t("story.title-2") }}
        </div>
        <div class="main-story-title-3">
          {{ $t("story.title-3") }}
        </div>
        <p><br /></p>
        <div class="main-story-content">
          {{ $t("story.content-1") }}
        </div>
        <div class="main-story-content">
          {{ $t("story.content-2") }}
        </div>
        <div class="main-story-content">
          {{ $t("story.content-3") }}
        </div>
        <div class="main-story-content">
          {{ $t("story.content-4") }}
        </div>
        <div class="main-story-read-more">
          <div class="read-more" @click="handleClickAboutReadMore">
            {{ $t("home.read-more") }}
            <fa-icon
              icon="fas fa-angles-right"
              beat
              color="#d3b673"
              class="sub-menu-icon"
            ></fa-icon>
          </div>
        </div>
      </div>

      <!-- service section -->
      <!-- <div class="services-section container" id="services">
        <div class="row">
          <div class="spacer-clearfix" style="height: 80px"></div>
          <div class="services-info col-md-12">
            <h2 class="section-title">{{ $t("service.our-best-services") }}</h2>
            <p class="services-main-text">
              {{ $t("service.intro") }}
            </p>
          </div>
          <div
            class="service col-lg-4 col-md-6 col-sm-12"
            v-for="(item, index) in serviceList"
            :key="index"
          >
            <div class="service-img">
              <img :src="item.imgUrl" />
            </div>
            <div
              class="service-title heading text-linear-effect"
              @click="
                handleClickPageDetail(
                  item.id,
                  PAGE_TYPE.SERVICE_PAGE,
                  item.slug
                )
              "
            >
              {{ item.title }}
            </div>
            <div class="description">{{ item.description }}</div>
            <div class="spacer-clearfix" style="height: 50px"></div>
          </div>
          <div class="spacer-clearfix" style="height: 80px"></div>
        </div>
      </div> -->

      <!-- slides - best service -->
      <div class="best-service">
        <div class="spacer-clearfix" style="height: 35px"></div>
        <div class="services-info col-md-12">
          <h2 class="section-title">{{ $t("service.our-best-services") }}</h2>
          <p class="services-main-text">
            {{ $t("service.intro") }}
          </p>
        </div>
      </div>
      <div class="slide-wrap">
        <div class="slide-inner">
          <div class="slide-item active">
            <img :src="landingPageImgs[0]" alt="magarims luxury spa" />
            <div class="slide-text">
              <!-- <h3>{{ $t("home.intro-important-1-1") }}</h3> -->
              <h2>{{ $t("service.clinic-service") }}</h2>
              <p>
                {{ $t("service.intro-clinic") }}
              </p>
              <button class="btn book-now-btn" @click="handeClickButtonClinic">
                {{ $t("button.view-more") }}
              </button>
            </div>
          </div>
          <div class="slide-item">
            <img :src="landingPageImgs[1]" alt="magarims luxury spa" />
            <div class="slide-text" style="text-align: left">
              <!-- <h3>{{ $t("home.intro-important-2-1") }}</h3> -->
              <h2>{{ $t("service.spa-service") }}</h2>
              <p>
                {{ $t("service.intro-spa") }}
              </p>
              <button class="btn book-now-btn" @click="handeClickButtonSpa">
                {{ $t("button.view-more") }}
              </button>
            </div>
          </div>
          <!-- <div class="slide-item">
            <img :src="landingPageImgs[2]" alt="magarims luxury spa" />
            <div class="slide-text" style="text-align: right">
              <h3>{{ $t("home.intro-important-3-1") }}</h3>
              <h1>{{ $t("home.intro-important-3-2") }}</h1>
              <p>
                {{ $t("home.intro-third") }}
              </p>
              <button class="btn book-now-btn" @click="handleClickBookNowBtn">
                {{ $t("button.book-now") }}
              </button>
            </div>
          </div> -->
        </div>
        <a class="slide-control" id="prev-slide"></a>
        <a class="slide-control" id="next-slide"></a>
        <ol class="slide-dot">
          <li class="active"></li>
          <li></li>
          <!-- <li></li> -->
        </ol>
      </div>

      <!-- about-section -->
      <!-- <div class="about-section container-fluid" id="about">
        <div class="row">
          <div class="video-box col-lg-6 col-md-12">
            <div class="play-btn">
              <fa-icon icon="fas fa-play"></fa-icon>
            </div>
          </div>
          <div class="about-main-text col-lg-6 col-md-12">
            <h2 class="section-title">{{ $t("story.our-story") }}</h2>
            <div class="sub-heading">
              <em>{{ $t("story.important-intro") }}</em>
            </div>
            <p class="text-box">
              {{ $t("story.intro") }}
            </p>
          </div>
        </div>
        <iframe
          src="https://www.youtube.com/embed/nO0gYLkcjYw"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="video-embed"
        >
        </iframe>
        <span class="close-btn"><fa-icon icon="fas fa-times"></fa-icon></span>
        <div class="spacer-clearfix" style="height: 80px"></div>
      </div> -->

      <!--  Experts say -->
      <div class="testimonial-experts-section container">
        <div class="section-title">{{ $t("review.experts-say") }}</div>
        <div class="spacer-clearfix" style="height: 35px"></div>
        <!-- <blockquote> -->
        <CustomerReview :reviewDataList="expertsSayList" />
        <!-- <div class="slick-slide-cite-experts">
            <p v-for="(item, index) in expertsSayList" :key="index">
              {{ item.description }}
            </p>
          </div>
          <div class="slick-slide-figure-img-experts">
            <div v-for="(item, index) in expertsSayList" :key="index">
              <img :src="item.imgUrl" alt="experts" />
            </div>
          </div>
          <div class="slick-slide-figure-info-experts">
            <div
              class="infomation"
              v-for="(item, index) in expertsSayList"
              :key="index"
            >
              <div class="name">{{ item.createdBy }}</div>
              <div class="position">{{ item.type }}</div>
            </div>
          </div> -->
        <!-- </blockquote> -->
        <div class="spacer-clearfix" style="height: 80px"></div>
      </div>

      <!-- Customer say -->
      <div class="testimonial-customer-section container">
        <div class="section-title">{{ $t("review.our-client-say") }}</div>
        <div class="spacer-clearfix" style="height: 35px"></div>
        <!-- <blockquote> -->
        <CustomerReview
          class="customer-review"
          :reviewDataList="clientSayList"
        />
        <!-- <div class="slick-slide-cite-customer">
            <p v-for="(item, index) in clientSayList" :key="index">
              {{ item.description }}
            </p>
          </div>
          <div class="slick-slide-figure-img-customer">
            <div v-for="(item, index) in clientSayList" :key="index">
              <img :src="item.imgUrl" alt="customer" />
            </div>
          </div>
          <div class="slick-slide-figure-info-customer">
            <div
              class="infomation"
              v-for="(item, index) in clientSayList"
              :key="index"
            >
              <div class="name">{{ item.createdBy }}</div>
              <div class="position">{{ item.type }}</div>
            </div>
          </div> -->
        <!-- </blockquote> -->
        <div class="spacer-clearfix" style="height: 80px"></div>
      </div>

      <!-- product -->
      <div class="pricing-plan container">
        <div class="spacer-clearfix" style="height: 80px"></div>
        <div class="row">
          <div class="heading col-12">
            <div class="main-heading section-title">
              {{ $t("product.products") }}
            </div>
            <div class="sub-heading">
              <p>
                {{ $t("product.intro") }}
              </p>
            </div>
          </div>
          <div class="spacer-clearfix" style="height: 24px"></div>
          <div class="menu-filter">
            <div class="filter-item active" title="All" data-id="all">
              {{ $t("product.all") }}
            </div>
            <div
              class="filter-item"
              v-for="(item, index) in serviceList"
              :key="index"
              :title="item.short_title"
              :data-id="item.subpage_type"
            >
              {{ item.short_title }}
            </div>
          </div>
          <div class="spacer-clearfix" style="height: 40px"></div>
          <ul class="pricing-menu col-12">
            <li
              class="pricing-item all facial massage"
              v-for="(item, index) in filterProductList"
              :key="index"
            >
              <!-- <span class="logo"><i class="natuspa-icon-oil"></i></span> -->
              <div class="sub-service-img">
                <img :src="item.imgUrl" />
              </div>
              <div class="heading">
                <h4
                  class="sub-service-title"
                  @click="
                    handleClickPageDetail(
                      item.id,
                      PAGE_TYPE.SERVICE_PAGE,
                      item.slug
                    )
                  "
                >
                  {{ item.title }}
                </h4>
                <div class="description">{{ item.shortDescription }}</div>
              </div>
            </li>
          </ul>
          <span id="filter-option"></span>
        </div>
        <div class="spacer-clearfix" style="height: 60px"></div>
      </div>

      <!-- collection section -->
      <!-- <div class="collection slide-custom">
        <div v-for="(item, index) in landingPageImgsBottom" :key="index">
          <a :href="item" class="collect-item">
            <img
              :src="item"
              alt="Gallery-1"
              class="collect-img"
              id="gallery-1"
            />
            <span class="plus-icon">
              <fa-icon icon="fas fa-plus"> </fa-icon>
            </span>
          </a>
        </div>
      </div> -->
      <!-- day spa packages -->
      <!-- <div class="spa-packages container-fluid">
        <div class="spacer-clearfix" style="height: 70px"></div>
        <div class="container">
          <div class="row">
            <div class="heading col-12">
              <h2 class="main-heading section-title">
                {{ $t("combo.day-spa-combo") }}
              </h2>
              <div class="sub-heading">
                <p>
                  {{ $t("combo.intro") }}
                </p>
              </div>
            </div>
            <div
              class="package-box col-lg-3 col-md-6 col-sm-12"
              :class="{ center: item.isCenter }"
              v-for="(item, index) in serviceComboList"
              :key="index"
            >
              <h2 class="section-title name-price-package">
                {{ item.title }}<br />
              </h2>
              <ul class="features">
                <li class="feature-item" v-html="item.description"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="spacer-clearfix" style="height: 80px"></div>
      </div> -->
      <!-- New Posts -->
      <!-- <div class="latest-blog" id="newPost">
        <div class="spacer-clearfix" style="height: 80px"></div>
        <div class="container-fluid pr-0">
          <div class="row">
            <div class="heading col-md-12">
              <div class="section-title">{{ $t("news.new-posts") }}</div>
              <div class="sub-heading">
                <p>
                  {{ $t("news.intro") }}
                </p>
              </div>
            </div>
            <div class="spacer-clearfix" style="height: 62px"></div>
            <div class="col-md-12 pr-0">
              <div class="news-slider">
                <div
                  class="news-box"
                  v-for="(item, index) in previewNewPostList"
                  :key="index"
                >
                  <span class="post-date">
                    <span class="time-blog">
                      {{ item.day }}
                    </span>
                  </span>
                  <a
                    class="post-title"
                    @click="
                      handleClickPageDetail(item.id, PAGE_TYPE.NEWS, item.slug)
                    "
                    >{{ item.title }}</a
                  >
                  <div class="post-content">
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                  <a
                    class="post-read-more link-text text-linear-effect"
                    @click="
                      handleClickPageDetail(item.id, PAGE_TYPE.NEWS, item.slug)
                    "
                  >
                    {{ $t("button.read-more") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="spacer-clearfix" style="height: 90px"></div>
      </div> -->

      <!-- latest blog -->
      <!-- <div class="latest-blog" id="latestBlog">
        <div class="spacer-clearfix" style="height: 80px"></div>
        <div class="container-fluid pr-0">
          <div class="row">
            <div class="heading col-md-12">
              <div class="section-title">
                {{ $t("blog.latest-blog-posts") }}
              </div>
              <div class="sub-heading">
                <p>
                  {{ $t("blog.intro") }}
                </p>
              </div>
            </div>
            <div class="spacer-clearfix" style="height: 62px"></div>
            <div class="col-md-12 pr-0">
              <div class="post-slider">
                <div
                  class="post-box"
                  v-for="(item, index) in previewBlogList"
                  :key="index"
                >
                  <span class="post-date">
                    <span class="time-blog">
                      {{ item.day }}
                    </span>
                  </span>

                  <a
                    class="post-title"
                    @click="
                      handleClickPageDetail(item.id, PAGE_TYPE.NEWS, item.slug)
                    "
                    >{{ item.title }}</a
                  >
                  <div class="post-content">
                    <p>
                      {{ item.description }}
                    </p>
                  </div>
                  <a
                    class="post-read-more link-text text-linear-effect"
                    @click="
                      handleClickPageDetail(item.id, PAGE_TYPE.BLOG, item.slug)
                    "
                  >
                    {{ $t("button.read-more") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="spacer-clearfix" style="height: 90px"></div>
      </div> -->

      <!-- Media say about us -->
      <!-- <div class="media-say-about-us-section">
        <div class="section-title">{{ $t("review.media-say-about-us") }}</div>
        <div class="spacer-clearfix" style="height: 35px"></div>
        <MediaSayAboutUs />
        <div class="spacer-clearfix" style="height: 80px"></div>
      </div> -->
      <!-- Statistics -->
      <div class="statistics-body lazyloaded">
        <div class="container py-5">
          <div class="row">
            <div class="col-6 col-lg-4 text-center mb-5">
              <span
                class="statistics-number display-1 d-inline-block lh-1 animated-number"
                >{{ statistics.numService }}+</span
              >
              <p class="statistics-label h-4 mt-4 mb-0">
                {{ $t("statistics.service") }}
              </p>
            </div>
            <div class="col-6 col-lg-4 text-center mb-5">
              <span
                class="statistics-number display-1 d-inline-block lh-1 animated-number"
                >0{{ statistics.numSpaSpace }}</span
              >
              <p class="statistics-label h-4 mt-4 mb-0">
                {{ $t("statistics.spa-space") }}
              </p>
            </div>
            <div class="col-6 col-lg-4 text-center mb-5">
              <span
                class="statistics-number display-1 d-inline-block lh-1 animated-number"
                >{{ statistics.numCustomer }}+</span
              >
              <p class="statistics-label h-4 mt-4 mb-0">
                {{ $t("statistics.customer") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- maps section - contact us -->
      <div class="maps container-fluid" id="contactUs">
        <div class="spacer-clearfix" style="height: 80px"></div>
        <div class="row">
          <div class="maps-embed col-md-12 col-lg-6 p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.046126231549!2d105.7856269!3d21.030840299999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135aba0448a2f3f%3A0xb38d41fe2a5d44e0!2sMagarims%20Luxury%20Spa!5e0!3m2!1sen!2s!4v1686394857272!5m2!1sen!2s"
              width="100%"
              height="100%"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
            >
            </iframe>
          </div>
          <div class="maps-contact col-md-12 col-lg-6">
            <div class="spacer-clearfix" style="height: 80px"></div>
            <div class="inner">
              <div class="row">
                <div class="contact col-lg-12 col-xl-6">
                  <div class="contact-popup-info">
                    <h2 class="section-title">
                      {{ $t("contact.office-ha-noi") }}
                    </h2>
                    <div class="address">
                      <span> 85 P. Thụy Khuê, Tây Hồ, Hà Nội </span>
                    </div>
                    <div class="email">
                      <span>magarimsluxuryspa@gmail.com</span>
                    </div>
                    <div class="phone">
                      <span>0877701184</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="contact col-lg-12 col-xl-6">
                  <div class="contact-popup-info">
                    <h2 class="section-title">Ho Chi Minh office</h2>
                    <div class="address">
                      <span>1 Nguyen Hue, Quan 1 district</span>
                    </div>
                    <div class="email">
                      <span>magarimsluxuryspa@gmail.com</span>
                    </div>
                    <div class="phone">
                      <span>+8400009999</span>
                    </div>
                  </div>
                </div> -->
                <div class="home-form-order">
                  <b-form
                    @submit.stop.prevent="handleClickOrderOrContact"
                    class="maps-form col-sm-12"
                  >
                    <div class="row">
                      <div class="form-input col-sm-6 your-name">
                        <b-form-group
                          id="example-input-group-1"
                          label-for="full-name-input"
                        >
                          <b-form-input
                            id="full-name-input"
                            name="full-name-input"
                            v-model="contactAndOrderOption.inputName"
                            :placeholder="$t('place-holder.your-full-name')"
                            :state="validateState('full-name-input')"
                            aria-describedby="full-name-live-feedback"
                            v-validate="{ required: true }"
                            data-vv-as="full name"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="full-name-live-feedback"
                            >{{
                              $t("validate.full-name")
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </div>
                      <div class="form-input col-sm-6 your-email">
                        <b-form-group
                          id="email-input-group"
                          label-for="email-input"
                        >
                          <b-form-input
                            id="email-input"
                            name="email-input"
                            v-model="contactAndOrderOption.inputEmail"
                            :placeholder="$t('place-holder.your-email')"
                            type="email"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="form-input col-sm-6 your-phone-number">
                        <b-form-group
                          id="number-phone-input-group"
                          label-for="number-phone-input"
                        >
                          <b-form-input
                            id="number-phone-input"
                            name="number-phone-input"
                            v-model="contactAndOrderOption.inputPhoneNumber"
                            :placeholder="$t('place-holder.your-phone')"
                            type="number"
                            :state="validateState('number-phone-input')"
                            aria-describedby="number-phone-live-feedback"
                            v-validate="{ required: true }"
                            data-vv-as="number phone"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            id="number-phone-live-feedback"
                            >{{
                              $t("validate.number-phone")
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </div>
                      <div class="form-input col-sm-6 your-service">
                        <b-form-group
                          id="service-input-group"
                          label-for="service-input"
                        >
                          <b-form-select
                            id="service-input"
                            name="service-input"
                            v-model="contactAndOrderOption.selectedService"
                            :options="serviceOptions"
                            label="select-service"
                          >
                          </b-form-select>
                        </b-form-group>
                      </div>
                      <b-form-textarea
                        id="textarea"
                        v-model="contactAndOrderOption.inputMessage"
                        :placeholder="$t('place-holder.your-message')"
                        rows="5"
                        max-rows="5"
                        no-resize
                      ></b-form-textarea>
                      <div class="form-input col-12 submit-btn">
                        <b-button
                          type="submit"
                          class="contact-btn"
                          :disabled="ordering"
                        >
                          <b-spinner small v-if="ordering"></b-spinner>
                          {{ $t("button.contact-us") }}
                        </b-button>
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer - About us-->
    <Footer />
    <!-- modal dialog book now -->
    <div class="modal-dialog-box" style="display: none">
      <div class="inner">
        <span class="close-icon" @click="handleCloseBookNowDialog">
          <fa-icon icon="fas fa-times"> </fa-icon>
        </span>
        <div class="content">
          <h3 class="heading">{{ $t("title.book-now") }}</h3>
          <form action="#" class="maps-form col-sm-12">
            <div class="row">
              <div class="form-input col-sm-6 your-name">
                <b-form-input
                  v-model="contactAndOrderOption.inputName"
                  :placeholder="$t('place-holder.your-full-name')"
                ></b-form-input>
              </div>
              <div class="form-input col-sm-6 your-email">
                <b-form-input
                  v-model="contactAndOrderOption.inputEmail"
                  :placeholder="$t('place-holder.your-email')"
                  type="email"
                ></b-form-input>
              </div>
              <div class="form-input col-sm-6 your-phone-number">
                <b-form-input
                  v-model="contactAndOrderOption.inputPhoneNumber"
                  :placeholder="$t('place-holder.your-phone')"
                  type="number"
                ></b-form-input>
              </div>
              <div class="form-input col-sm-6 your-service">
                <b-form-select
                  v-model="contactAndOrderOption.selectedService"
                  :options="serviceOptions"
                  label="select-service"
                >
                </b-form-select>
              </div>
              <b-form-textarea
                id="textarea"
                v-model="contactAndOrderOption.inputMessage"
                :placeholder="$t('place-holder.your-message')"
                rows="5"
                max-rows="5"
                no-resize
              ></b-form-textarea>
              <div class="form-input col-12 submit-btn">
                <b-button class="order-btn" variant="primary">
                  {{ $t("button.order") }}
                </b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import $ from "jquery";
import "magnific-popup";
import axios from "axios";
// import Header from "@/components/Header.vue";
import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/Footer.vue";
import CustomerReview from "@/components/CustomerReview.vue";
// import MediaSayAboutUs from "@/components/MediaSayAboutUs.vue";
import {
  PAGES_API,
  MENU_API,
  USER_CONTACT_API,
  IMAGES_LIST_API,
} from "@/constants/api-path";
import { PAGE_TYPE } from "@/constants/common";
import Hls from "hls.js";
export default {
  name: "Home",
  components: {
    // Header,
    Navigation,
    Footer,
    CustomerReview,
    // MediaSayAboutUs,
  },
  data() {
    return {
      PAGE_TYPE: PAGE_TYPE,
      landingPageImgs: [
        require("@/assets/slide/banner_home_page_01.png"),
        require("@/assets/slide/banner_home_page_02.png"),
        require("@/assets/slide/banner_home_page_03.png"),
      ],
      serviceList: [],
      clientSayList: [],
      expertsSayList: [],
      productList: [],
      // previewNewPostList: [],
      // previewBlogList: [],
      // serviceComboList: [],
      contactAndOrderOption: {
        inputName: "",
        inputEmail: "",
        inputPhoneNumber: "",
        selectedService: null,
        inputMessage: "",
      },

      selectedPageId: null,
      isShowDialog: false,
      postType: "",
      activeService: "all",
      // headerServiceList: [],
      // headerPreviewNewPostList: [],
      // headerPreviewBlogList: [],
      // landingPageImgsBottom: [],
      statistics: {
        numService: 15,
        numSpaSpace: 5,
        numCustomer: 3000,
      },
      ordering: false,
    };
  },
  created() {
    // this.requestGetListImageLandingPageBottom();
    // this.requestGetDataList(PAGE_TYPE.SERVICE_PAGE, false, false, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.SERVICE_PAGE, false, false, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.SERVICE_COMBO, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    this.requestGetServiceList();
    this.requestGetDataList(PAGE_TYPE.CLIENT_SAY, false, null, {
      page: 1,
      limit: 30,
    });
    this.requestGetDataList(PAGE_TYPE.EXPERTS_SAY, false, null, {
      page: 1,
      limit: 30,
    });
    // this.requestGetDataList(PAGE_TYPE.BLOG, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetDataList(PAGE_TYPE.NEWS, false, true, {
    //   page: 1,
    //   limit: 30,
    // });
    // this.requestGetListImageLandingPage();
  },
  mounted() {
    this.detectDevTool();
    this.initLoading();
    this.loadVideoResource();
    this.createEventListenerForVideo();
    // this.initAnimationHeader();
    this.initAnimationHeaderSlide();
    // this.initAnimation();
    this.scrollToElement();
    this.statisticsAnimation("SERVICE", 0, 15, 5000);
    this.statisticsAnimation("SPA_SPACE", 0, 5, 5000);
    this.statisticsAnimation("CUSTOMER", 0, 3000, 5000);
  },
  computed: {
    serviceOptions() {
      const options = this.productList.map((item) => item.title);
      options.unshift({
        value: null,
        text: this.$t("place-holder.select-service"),
      });
      return options;
    },
    filterProductList() {
      if (this.activeService === "all") {
        return this.productList;
      }
      return this.productList.filter(
        (item) => item.short_title === this.activeService
      );
    },
  },
  watch: {
    // landingPageImgs(value) {
    //   if (value) {
    //     setTimeout(() => {
    //       this.initAnimationHeaderSlide();
    //     }, 50);
    //   }
    // },
    // serviceList(value) {
    //   if (value) {
    //     this.headerServiceList = value.slice(0, 3);
    //   }
    // },
    productList(value) {
      if (value) {
        setTimeout(() => {
          this.initAnimationProduct();
        }, 50);
      }
    },
    // clientSayList(value) {
    //   if (value) {
    //     setTimeout(() => {
    //       this.initAnimationCustomerSay();
    //     }, 50);
    //   }
    // },
    // expertsSayList(value) {
    //   if (value) {
    //     setTimeout(() => {
    //       this.initAnimationExpertsSay();
    //     }, 50);
    //   }
    // },
    // previewNewPostList(value) {
    //   if (value) {
    //     this.headerPreviewNewPostList = value.slice(0, 3);

    //     setTimeout(() => {
    //       this.initAnimationNewsPost();
    //     }, 50);
    //   }
    // },
    // previewBlogList(value) {
    //   if (value) {
    //     this.headerPreviewBlogList = value.slice(0, 4);

    //     setTimeout(() => {
    //       this.initAnimationBlogPost();
    //     }, 50);
    //   }
    // },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    detectDevTool() {
      const start = new Date();
      debugger;
      const end = new Date();
      if (isNaN(start) || isNaN(end) || end - start > 10) {
        window.location = "about:blank";
      }
    },

    scrollToElement() {
      if (this.$route.hash) {
        const element = document.getElementById(this.$route.hash.substring(1));
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1000);
      }
    },
    handleChangeLang() {
      location.reload();
    },
    // handleClickBookNowBtn() {
    //   // let windows = $(window);
    //   // if (windows.width() > 855) {
    //   //   $("#overlay").css("display", "block");
    //   //   $(".modal-dialog-box").show(350);
    //   // } else {
    //   window.location.href = "#contactUs";
    //   // }
    // },
    handeClickButtonClinic() {
      this.$router.push("/service/clinic-service/1");
    },
    handeClickButtonSpa() {
      this.$router.push("/service/spa-service/1");
    },
    handleCloseBookNowDialog() {
      $("#overlay").css("display", "none");
      $(".modal-dialog-box").hide(350);
    },
    requestGetServiceList() {
      axios
        .get(this.$config.backendApi + MENU_API, {
          params: {
            page_type: PAGE_TYPE.SERVICE_PAGE,
            lang: localStorage.getItem("lang") === "en" ? 1 : 0,
          },
        })
        .then((res) => {
          if (res && res.data) {
            this.buildDataForService(res.data);
          }
        });
    },
    buildDataForService(data) {
      data.forEach((serviceLevelOne) => {
        this.serviceList.push({
          short_title: serviceLevelOne.short_title,
          subpage_type: serviceLevelOne.short_title,
        });
        serviceLevelOne.childs.forEach((serviceLevelTwo) => {
          serviceLevelTwo.childs.forEach((serviceLevelThree) => {
            this.productList.push({
              short_title: serviceLevelOne.short_title,
              shortDescription: this.buildProductShortDescription(
                serviceLevelThree.short_desc
              ),
              id: serviceLevelThree.id,
              imgUrl: serviceLevelThree.thumbnail,
              title: serviceLevelThree.title,
              description: serviceLevelThree.short_desc,
              slug: serviceLevelThree.slug,
            });
          });
        });
      });
    },
    requestGetDataList(dataType, isDeleted, isParentPage, paging) {
      const requestBody = {
        page: paging.page,
        limit: paging.limit,
        filter: {
          is_deleted: isDeleted,
          page_type: dataType,
          lang: localStorage.getItem("lang") === "en" ? 1 : 0,
        },
        sort: {
          updated_at: 1,
        },
      };
      if (dataType === PAGE_TYPE.SERVICE_PAGE) {
        requestBody.filter.is_parent_page = isParentPage;
      }

      axios
        .post(this.$config.backendApi + PAGES_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.buildDataList(dataType, res.data, isParentPage);
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    buildDataList(dataType, data, isParentPage) {
      const dataList = [];
      data.forEach((element, index) => {
        const dataObject = {
          id: element.id,
          imgUrl: element.thumbnail,
          title: element.title,
          description: element.short_desc,
          slug: element.seo.slug,
        };
        if (dataType === PAGE_TYPE.EXPERTS_SAY) {
          dataObject.type = this.$t("review.experts");
          dataObject.createdBy = element.created_by;
        } else if (dataType === PAGE_TYPE.CLIENT_SAY) {
          dataObject.type = this.$t("review.customer");
          dataObject.createdBy = element.created_by;
        } else if (dataType === PAGE_TYPE.BLOG || dataType === PAGE_TYPE.NEWS) {
          const date = new Date(element.created_at);
          dataObject.day = date.getDate() + "/" + date.getMonth() + 1;
          // dataObject.month = date.getMonth() + 1;
        } else if (dataType === PAGE_TYPE.SERVICE_PAGE && isParentPage) {
          dataObject.short_title = element.short_title;
          dataObject.subpage_type = element.subpage_type;
        } else if (dataType === PAGE_TYPE.SERVICE_PAGE && !isParentPage) {
          dataObject.short_title = element.short_title;
          dataObject.shortDescription = this.buildProductShortDescription(
            element.short_desc
          );
        } else if (dataType === PAGE_TYPE.SERVICE_COMBO) {
          dataObject.description = dataObject.description.replace(
            /\n/g,
            "<br>"
          );
          if (index === 1) {
            dataObject.isCenter = true;
          }
        }
        dataList.push(dataObject);
      });

      switch (dataType) {
        case PAGE_TYPE.SERVICE_PAGE:
          if (isParentPage) {
            this.serviceList = dataList;
          } else {
            this.productList = dataList;
          }
          break;
        case PAGE_TYPE.CLIENT_SAY:
          this.clientSayList = dataList;
          break;
        case PAGE_TYPE.EXPERTS_SAY:
          this.expertsSayList = dataList;
          break;
        // case PAGE_TYPE.BLOG:
        //   this.previewBlogList = dataList;
        //   break;
        // case PAGE_TYPE.NEWS:
        //   this.previewNewPostList = dataList;
        //   break;
        // case PAGE_TYPE.SERVICE_COMBO:
        //   this.serviceComboList = dataList;
        //   break;
        default:
          break;
      }
    },
    handleClickAboutReadMore() {
      this.$router.push("/about#about-us");
      window.scrollTo(0, 0);
    },
    handleClickOrderOrContact() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.requestOrderAndContact();
        this.handleCloseBookNowDialog();
      });
    },
    resetFormData() {
      this.contactAndOrderOption.inputName = "";
      this.contactAndOrderOption.inputEmail = "";
      this.contactAndOrderOption.inputPhoneNumber = "";
      this.contactAndOrderOption.selectedService = null;
      this.contactAndOrderOption.inputMessage = "";
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    requestOrderAndContact() {
      this.ordering = true;
      const requestBody = {
        email: this.contactAndOrderOption.inputEmail,
        name: this.contactAndOrderOption.inputName,
        phone: this.contactAndOrderOption.inputPhoneNumber,
        service_type: this.contactAndOrderOption.selectedService,
        message: this.contactAndOrderOption.inputMessage,
      };
      axios
        .put(this.$config.backendApi + USER_CONTACT_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.makeNotification(
              "success",
              this.$t("title.success"),
              this.$t("message.contact-success")
            );
            this.resetFormData();
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        })
        .finally(() => {
          this.ordering = false;
        });
    },
    handleClickPageDetail(id, typeTitle, slug) {
      this.selectedPageId = id;
      switch (typeTitle) {
        case PAGE_TYPE.BLOG:
          this.postType = this.$t("title.blog");
          this.$router.push(`/page/detail/${this.selectedPageId}/${slug}`);
          break;
        case PAGE_TYPE.NEW:
          this.postType = this.$t("title.news");
          break;
        case PAGE_TYPE.SERVICE_PAGE:
          this.postType = this.$t("title.services");
          slug = slug ? slug : "luxury-service";
          this.$router.push(`/view-detail/${this.selectedPageId}`);
          break;
        default:
          break;
      }
      this.destroyAnimation();
      // this.$refs.pageDetailDialog.$refs.dialog.show();
      // slug = slug ? slug : "luxury-service";
      // this.$router.push(`/page/detail/${this.selectedPageId}/${slug}`);
    },

    requestGetListImageLandingPageBottom() {
      const requestBody = {
        page: 1,
        limit: 1000,
        filter: {
          is_deleted: false,
          type: 1,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + IMAGES_LIST_API, requestBody)
        .then(async (res) => {
          if (res && res.data) {
            this.landingPageImgsBottom = await res.data[0].images;
            setTimeout(() => {
              // this.initAnimationCollection();
              // this.initAnimationCollectionBottom();
            }, 1000);
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },

    requestGetListImageLandingPage() {
      const requestBody = {
        page: 1,
        limit: 3,
        filter: {
          is_deleted: false,
          type: 0,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + IMAGES_LIST_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.landingPageImgs = res.data[0].images;
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    buildProductShortDescription(inputVal) {
      return inputVal.slice(0, 27) + " ...";
    },
    makeNotification(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    initLoading() {
      $("body").removeClass(".preload");
      $("#loader").delay(1000).fadeOut("fast");
    },
    initAnimationHeader() {
      let windows = $(window);

      if (windows.width() < 1190) {
        $(".main-nav").attr("id", "main-nav-mobile");
      }
      windows.resize(function () {
        let width = windows.width();
        if (width < 1190) {
          $(".main-nav").attr("id", "main-nav-mobile");
        } else {
          $(".main-nav").attr("id", "");
        }
      });

      // ====================== dropdown navigation ============================
      var addDropdownIcon = function () {
        $(".menu-item-has-child").each(function () {
          let currentItem = $(this).find(".nav-link");
          let currentText = currentItem.text();
          currentItem.html(
            currentText +
              '<span class="icon-dropdown"><fa-icon icon="fas fa-caret-down"></fa-icon></span>'
          );
        });
      };
      if ($(".main-nav").attr("id") === "main-nav-mobile") {
        addDropdownIcon();
      }
      windows.resize(function () {
        if (windows.width() < 1190) {
          addDropdownIcon();
        } else {
          $(".icon-dropdown").css("display", "none");
        }
      });
    },
    initAnimationHeaderSlide() {
      // ======================= slide header ==================================
      //add id for slide and slide-dot
      $(".slide-item").each(function (index) {
        $(this).attr("id", index + 1);
      });
      $(".slide-dot li").each(function (index) {
        $(this).attr("id", index + 1);
      });

      // slide / slides
      var addIndexForPrevSlide = function () {
        let totalSlides = $(".slide-item").length;
        let currentIndex = $(".slide-item.active").attr("id");
        let Index = 1;
        if (parseInt(currentIndex) == 1) {
          Index = totalSlides;
        } else {
          Index = parseInt(currentIndex) - 1;
        }
        if (!Index) {
          Index = 1;
          toPrevSlide();
        }

        $("#prev-slide").text(Index + "/" + totalSlides);
      };

      var addIndexForNextSlide = function () {
        let totalSlides = $(".slide-item").length;
        let currentIndex = $(".slide-item.active").attr("id");
        let Index = 1;
        if (parseInt(currentIndex) == 3) {
          Index = 1;
        } else {
          Index = parseInt(currentIndex) + 1;
        }
        if (!Index) {
          Index = 1;
          toNextSlide();
        }
        $("#next-slide").text(Index + "/" + totalSlides);
      };

      addIndexForPrevSlide();
      addIndexForNextSlide();

      // add animation for slide text
      var addAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item.active .slide-text");
        // activeSlide.find("h3").addClass("animated fadeInUp");
        activeSlide.find("h2").addClass("animated fadeInDown");
        activeSlide.find("p").addClass("animated fadeInUp");
        activeSlide.find("button").addClass("animated fadeInRight");
      };
      var removeAnimatedForSlideText = function () {
        let activeSlide = $(".slide-item.active .slide-text");
        // activeSlide.find("h3").removeClass("animated fadeInUp");
        activeSlide.find("h2").removeClass("animated fadeInDown");
        activeSlide.find("p").removeClass("animated fadeInUp");
        activeSlide.find("button").removeClass("animated fadeInRight");
      };

      addAnimatedForSlideText();

      // next and prev button
      var toNextSlide = function () {
        let activeSlide = $(".slide-item.active");
        let nextSlide = activeSlide.next();
        let activeSlideDot = $(".slide-dot li.active");
        let nextSlideDot = activeSlideDot.next();

        if (nextSlide.length !== 0) {
          removeAnimatedForSlideText();
          activeSlide
            .addClass("fadeOut")
            .one("webkitAnimationEnd", function () {
              $(".fadeOut").removeClass("fadeOut").removeClass("active");
            });

          nextSlide
            .addClass("fadeIn active")
            .one("webkitAnimationEnd", function () {
              addAnimatedForSlideText();
              $(".fadeIn").removeClass("fadeIn");
              addIndexForPrevSlide();
              addIndexForNextSlide();
            });

          activeSlideDot.removeClass("active");
          nextSlideDot.addClass("active");
        } else {
          removeAnimatedForSlideText();
          activeSlide
            .addClass("fadeOut")
            .one("webkitAnimationEnd", function () {
              $(".fadeOut").removeClass("fadeOut").removeClass("active");
            });
          $(".slide-item:first-child")
            .addClass("fadeIn active")
            .one("webkitAnimationEnd", function () {
              addAnimatedForSlideText();
              $(".fadeIn").removeClass("fadeIn");
              addIndexForPrevSlide();
              addIndexForNextSlide();
            });
          activeSlideDot.removeClass("active");
          $(".slide-dot li:first-child").addClass("active");
        }
      };
      var toPrevSlide = function () {
        let activeSlide = $(".slide-item.active");
        let prevSlide = activeSlide.prev();
        let activeSlideDot = $(".slide-dot li.active");
        let prevSlideDot = activeSlideDot.prev();
        if (prevSlide.length !== 0) {
          removeAnimatedForSlideText();
          activeSlide
            .addClass("fadeOut")
            .one("webkitAnimationEnd", function () {
              $(".fadeOut").removeClass("fadeOut").removeClass("active");
            });
          prevSlide
            .addClass("fadeIn active")
            .one("webkitAnimationEnd", function () {
              addAnimatedForSlideText();
              $(".fadeIn").removeClass("fadeIn");
              addIndexForPrevSlide();
              addIndexForNextSlide();
            });
          activeSlideDot.removeClass("active");
          prevSlideDot.addClass("active");
        } else {
          removeAnimatedForSlideText();
          activeSlide
            .addClass("fadeOut")
            .one("webkitAnimationEnd", function () {
              $(".fadeOut").removeClass("fadeOut").removeClass("active");
            });
          $(".slide-item:last-child")
            .addClass("fadeIn active")
            .one("webkitAnimationEnd", function () {
              addAnimatedForSlideText();
              $(".fadeIn").removeClass("fadeIn");
              addIndexForPrevSlide();
              addIndexForNextSlide();
            });
          activeSlideDot.removeClass("active");
          $(".slide-dot li:last-child").addClass("active");
        }
      };
      var autoTurnSlide = function () {
        setInterval(() => {
          let rand = Math.random();
          if (rand < 0.5) toNextSlide();
          else toPrevSlide();
        }, 30000);
      };

      $("#next-slide").on("click", function () {
        toNextSlide();
      });

      $("#prev-slide").on("click", function () {
        toPrevSlide();
      });

      autoTurnSlide();
      // Move slide with slide dot
      $(".slide-dot li").on("click", function () {
        $(".slide-dot li.active").removeClass("active");
        $(this).addClass("active");
        let id = $(this).attr("id");
        let activeSlide = $(".slide-item.active");
        let nextSlide = null;
        $(".slide-item").each(function () {
          if ($(this).attr("id") === id) nextSlide = $(this);
        });

        removeAnimatedForSlideText();
        activeSlide.addClass("fadeOut").one("webkitAnimationEnd", function () {
          $(".fadeOut").removeClass("fadeOut").removeClass("active");
        });

        nextSlide
          .addClass("fadeIn active")
          .one("webkitAnimationEnd", function () {
            addAnimatedForSlideText();
            $(".fadeIn").removeClass("fadeIn");
            addIndexForPrevSlide();
            addIndexForNextSlide();
          });
      });
    },
    initAnimationCollectionBottom() {
      $(".collection").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });
    },
    initAnimationExpertsSay() {
      // =============================== Slick of experts say =====================================
      $(".slick-slide-cite-experts").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor:
          ".slick-slide-figure-img-experts, .slick-slide-figure-info-experts",
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });

      $(".slick-slide-figure-img-experts").slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 0,
        asNavFor: ".slick-slide-cite-experts, .slick-slide-figure-info-experts",
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });

      $(".slick-slide-figure-info-experts").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: ".slick-slide-cite-experts, .slick-slide-figure-img-experts",
        focusOnSelect: true,
      });
    },
    initAnimationCustomerSay() {
      // =============================== Slick of customer say =====================================
      $(".slick-slide-cite-customer").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor:
          ".slick-slide-figure-img-customer, .slick-slide-figure-info-customer",
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });

      $(".slick-slide-figure-img-customer").slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: 0,
        asNavFor:
          ".slick-slide-cite-customer, .slick-slide-figure-info-customer",
        focusOnSelect: true,
        infinite: true,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });

      $(".slick-slide-figure-info-customer").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor:
          ".slick-slide-cite-customer, .slick-slide-figure-img-customer",
        focusOnSelect: true,
      });
    },
    initAnimationCollection() {
      // ========================= Collection section =============================
      $(".collection").magnificPopup({
        type: "image",
        delegate: "a",
        fixedContentPos: false,
        gallery: {
          enabled: true,
        },
        removalDelay: 300,
        mainClass: "mfp-fade",
        zoom: {
          enabled: true,
          duration: 550,
          easing: "ease-in-out",
          opener: function (openerElement) {
            return openerElement.is("img")
              ? openerElement
              : openerElement.find("img");
          },
        },
      });

      // detect mouse direction use JavaScript
      var direction = "";
      var oldX = 0;
      var oldY = 0;
      var mousemovemethod = function (e) {
        if (e.pageX > oldX && e.pageY == oldY) {
          direction = "left-right";
        } else if (e.pageX == oldX && e.pageY > oldY) {
          direction = "top-bottom";
        } else if (e.pageX == oldX && e.pageY < oldY) {
          direction = "bottom-top";
        } else if (e.pageX < oldX && e.pageY == oldY) {
          direction = "right-left";
        }
        oldX = e.pageX;
        oldY = e.pageY;
      };
      document.addEventListener("mousemove", mousemovemethod);

      $(".collect-item").on("mouseenter", function () {
        let active = $(this);
        let currentDirec;
        // static variable : fix the direction is constantly changing
        if (typeof currentDirec == "undefined") {
          currentDirec = direction;
          document.removeEventListener("mousemove mousedown", mousemovemethod);
        }
        // add class fade in
        active.addClass("appear-" + currentDirec);
        active.find(".plus-icon").show(150);
        document.addEventListener("mousemove", mousemovemethod);
        // mouse leave -> add class fade out and remove class fade in
        active.on("mouseleave", function () {
          active.find(".plus-icon").hide(150);
          active
            .addClass("disappear-" + currentDirec)
            .one("webkitAnimationEnd", function () {
              active.removeClass("appear-" + currentDirec);
              active.removeClass("disappear-" + currentDirec);
            });
        });
      });
    },
    initAnimationNewsPost() {
      // ===================== slick slider news ==========================
      $(".news-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: false,
            },
          },
          {
            breakpoint: 821,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: false,
            },
          },
          {
            breakpoint: 393,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    },
    initAnimationBlogPost() {
      // ===================== slick slider latest blog post ==========================
      $(".post-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: false,
            },
          },
          {
            breakpoint: 821,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: false,
            },
          },
          {
            breakpoint: 393,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    },

    initAnimationProduct() {
      // ========================= Filter - Pricing Plan ==========================
      const self = this;
      let activeFilter = $(".filter-item.active");
      $(".filter-item").on("click", function () {
        activeFilter.removeClass("active");
        $(this).addClass("active");
        activeFilter = $(this);
        self.activeService = activeFilter.attr("data-id");
      });
    },

    initAnimation() {
      // =============================== Embed video ===============================
      let videoEmbed = $(".video-embed");
      $(".play-btn").on("click", function () {
        videoEmbed.show(350);
        $("#overlay").css("display", "block");
        $(".close-btn").show(350);
      });
      $(".about-section .close-btn").on("click", function () {
        videoEmbed.hide(350);
        $("#overlay").css("display", "none");
        $(".close-btn").hide(350);
      });

      // Form Validation
      var isEmpty = function (item) {
        if (!item) return true;
        return false;
      };
      var isEmail = function (email) {
        let regex = /^([a-zA-Z0-9_])+\\@(([a-zA-Z]{3,})+\.com)$/i;
        return regex.test(email);
      };
      var isPhoneNumber = function (phoneNumber) {
        let regex = /^\d{10,11}$/;
        return regex.test(phoneNumber);
      };
      var checkTypeOfServices = function (tOS) {
        if (tOS.attr("data-value") == "0") return false;
        return true;
      };

      var onError = function (selector) {
        selector.next().css("display", "block");
        selector.addClass("apply-shake").one("webkitAnimationEnd", function () {
          selector.removeClass("apply-shake");
        });
      };
      var onNoError = function (selector) {
        selector.next().css("display", "none");
        selector.removeClass("apply-shake");
      };

      var validateForm = function (selectorName) {
        let selector = $(selectorName + " .form-input input");
        // check empty
        selector.each(function () {
          if (isEmpty($(this).val())) onError($(this));
          else onNoError($(this));
        });
        // check email
        let email = $(selectorName + " #email");
        if (isEmail(email.val())) onNoError(email);
        else onError(email);
        // check phone number
        let phone = $(selectorName + " #phone-number");
        if (isPhoneNumber(phone.val())) onNoError(phone);
        else onError(phone);
        // check type of services
        let typeOfService = $(selectorName + " .current-option");
        if (checkTypeOfServices(typeOfService)) onNoError(typeOfService);
        else onError(typeOfService);
      };

      // ============================== maps section =================================
      $(".maps .submit-btn").on("click", function () {
        validateForm(".maps");
      });
    },
    destroyAnimation() {
      $(".slick-slide-cite-experts").slick("unslick");
      $(".slick-slide-figure-img-experts").slick("unslick");
      $(".slick-slide-figure-info-experts").slick("unslick");
      $(".slick-slide-cite-customer").slick("unslick");
      $(".slick-slide-figure-img-customer").slick("unslick");
      $(".slick-slide-figure-info-customer").slick("unslick");
      $(".news-slider").slick("unslick");
      $(".post-slider").slick("unslick");
      $(".collection").slick("unslick");
    },

    // ============================== Caculate Statistics =================================
    statisticsAnimation(statisticType, start, end, duration) {
      const range = end - start;
      const minTimer = 50;
      let stepTime = Math.abs(Math.floor(duration / range));
      stepTime = Math.max(stepTime, minTimer);
      const startTime = new Date().getTime();
      const endTime = startTime + duration;
      let timer;
      const self = this;
      function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - remaining * range);
        switch (statisticType) {
          case "SERVICE":
            self.statistics.numService = value;
            break;
          case "SPA_SPACE":
            self.statistics.numSpaSpace = value;
            break;
          case "CUSTOMER":
            self.statistics.numCustomer = value;
            break;
          default:
            break;
        }
        if (value == end) {
          clearInterval(timer);
        }
      }
      timer = setInterval(run, stepTime);
      run();
    },
    loadVideoResource() {
      var video = this.$refs.video;
      if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(
          "https://magarimsluxuryspa.com/api/image/magarims/master.m3u8"
        );
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src =
          "https://magarimsluxuryspa.com/api/image/magarims/master.m3u8";
      }
    },
    playingVideo() {
      var image = document.getElementById("background-image-video");
      var button = document.getElementById("play-video-button");
      image.style.display = "none";
      button.style.display = "none";
      var video = this.$refs.video;
      video.play();
    },
    createEventListenerForVideo() {
      var image = document.getElementById("background-image-video");
      var button = document.getElementById("play-video-button");
      var video = this.$refs.video;
      video.addEventListener("playing", function () {
        console.log("Video is now playing");
        image.style.display = "none";
        button.style.display = "none";
      });
      video.addEventListener("ended", function () {
        image.style.display = "block";
      });
      video.addEventListener("pause", function () {
        button.style.display = "block";
      });
    },
  },
};
</script>

<style scoped>
body {
  background-image: url("../../assets/flower1.svg");
  background-size: contain;
}

.time-blog {
  line-height: 42px;
}
.slide-custom {
  /* display: none; */
}

@media only screen and (max-width: 1440px) {
  .video-frame {
    height: 520px !important;
  }
}

@media only screen and (max-width: 767px) {
  .video-frame {
    height: 400px !important;
  }
}

@media only screen and (max-width: 480px) {
  .video-frame {
    height: 260px !important;
  }
}

@media only screen and (max-width: 375px) {
  .video-frame {
    height: 220px !important;
  }
}
.video-frame,
.video-frame video {
  width: 100%;
  height: 100%;
  /* height: 100vh; */
}

video,
.frame-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
}
.image-background-video {
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("/imgs/background-video-tra-man-quan.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.play-btn {
  cursor: pointer;
}
.services-section {
  margin-top: 80px;
}

@media only screen and (max-width: 600px) {
  .main-story {
    margin: 100px auto 0px auto;
    width: 95%;
  }

  .best-service {
    margin: 100px auto 0px auto;
    width: 95%;
  }

  .testimonial-experts-section {
    margin-top: 0px;
  }

  .pricing-plan {
    padding: 20px !important;
  }

  .sub-heading {
    max-width: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .main-story {
    margin: 100px auto 0px auto;
    width: 85%;
  }

  .best-service {
    margin: 100px auto 0px auto;
    width: 85%;
  }

  .testimonial-experts-section {
    margin-top: 0px;
  }

  .sub-heading {
    max-width: 85%;
  }
}

@media only screen and (min-width: 992px) {
  .main-story {
    margin: 100px auto 0px auto;
    width: 80%;
  }

  .best-service {
    margin: 100px auto 0px auto;
    width: 80%;
  }

  .testimonial-experts-section {
    margin-top: 0px;
  }

  .sub-heading {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .main-story {
    margin: 100px auto 0px auto;
    width: 65%;
  }
  .testimonial-experts-section {
    margin-top: 100px;
  }
  .best-service {
    margin: 100px auto 0px auto;
    width: 65%;
  }

  .sub-heading {
    max-width: 65%;
  }
}

.testimonial-customer-section {
  /* margin-top: 50px; */
}

.media-say-about-us-section {
  margin: 50px 0px;
}

.main-story-title-1 {
  text-align: center;
  font-family: Playfair Display;
  text-transform: capitalize;
  font-size: 60px;
  letter-spacing: 0.6px;
}

.main-story-title-1::after {
  content: " ";
  width: 50px;
  height: 2px;
  background-color: #72543a !important;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
}

.main-story-title-2 {
  text-align: center;
  text-transform: capitalize;
  font-size: 38px;
  font-weight: 500;
  color: #72543a;
}

.main-story-title-3 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  font-family: system-ui;
  font-style: italic;
}

.main-story-content {
  text-align: justify;
  line-height: 30px;
  font-size: 18px;
  margin: 10px 30px 10px 30px;
}

.main-story-read-more {
  margin: 20px 0 0 30px;
  color: #72543a;
  font-size: 18px;
}

.read-more:hover {
  color: #72543a;
  cursor: pointer;
}

.statistics-body {
  background-position: center bottom;
  background-image: url("../../assets/statistics_background.png");
  background-color: #333;
  background-repeat: no-repeat;
  background-size: cover;
}

.statistics-number {
  font-family: "Cooper Black";
  color: white;
}

.statistics-label {
  font-family: "sans-serif";
  font-size: 26px;
  color: white;
  font-weight: 300;
  text-transform: uppercase;
}
</style>
