<template>
  <div class="tra-man-quan-container" id="tra-man-quan">
    <!-- Loading ......... -->
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>
    <!-- scroll top -->
    <a class="scroll-top link-text" href="#tra-man-quan">
      <fa-icon icon="fas fa-angles-right" beat></fa-icon>
    </a>
    <Navigation :isCustomVideo="true">
      <template v-slot:slide>
        <div class="slide-wrap-menu">
          <div class="slide-inner">
            <div class="slide-item active video-frame">
              <video ref="video" loop autoplay controls></video>
              <div id="background-image-video" class="image-background-video">
                <div class="play-btn" @click="playingVideo">
                  <fa-icon icon="fas fa-play"></fa-icon>
                </div>
              </div>
              <div
                id="play-video-button"
                class="play-btn"
                @click="playingVideo"
              >
                <fa-icon icon="fas fa-play"></fa-icon>
              </div>
              <div class="slide-text">
                <h1>{{ $t("about-us.title-header") }}</h1>
                <p>
                  {{ $t("about-us.content-header") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Navigation>

    <!-- Content -->
    <div class="tra-man-quan-detail-container">
      <div class="title-tra-man-quan-wrap">
        <h2
          class="title-tra-man-quan-detail"
          v-for="(item, index) in listHeaderDetail"
          :key="index"
        >
          {{ $t(item) }}
        </h2>
      </div>
      <div class="content-section">
        <div class="content-element">
          <div class="content-description">
            <p>
              {{ $t("tra-man-quan.content-detail-1") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-2") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-3") }}
            </p>
          </div>
          <div class="content-image">
            <img
              src="@/components/imgs/tra-man-quan/tra-man-quan-1.jpg"
              alt="magarims luxury spa"
            />
          </div>
        </div>

        <div class="content-element content-reverse">
          <div class="content-description">
            <p>
              {{ $t("tra-man-quan.content-detail-4") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-5") }}
            </p>
          </div>
          <div class="content-image">
            <img
              src="@/components/imgs/tra-man-quan/tra-man-quan-2.jpg"
              alt="magarims luxury spa"
            />
          </div>
        </div>

        <div class="content-element">
          <div class="content-description">
            <p>
              {{ $t("tra-man-quan.content-detail-6") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-7") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-8") }}
            </p>
            <p>
              {{ $t("tra-man-quan.content-detail-9") }}
            </p>
          </div>
          <div class="content-image">
            <img
              src="@/components/imgs/tra-man-quan/tra-man-quan-4.jpg"
              alt="magarims luxury spa"
            />
          </div>
        </div>
      </div>

      <!-- Galler -->
      <h3
        class="title-galler"
        v-for="(item, index) in listHeaderGaller"
        :key="index"
      >
        {{ $t(item) }}
      </h3>
      <CarouselCollection :images="landingPageImgsBottom" />
    </div>

    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/common/Navigation.vue";
import $ from "jquery";
import { IMAGES_LIST_API } from "@/constants/api-path";
import axios from "axios";
import Footer from "@/components/Footer.vue";
import Hls from "hls.js";
import CarouselCollection from "@/components/common/CarouselCollection.vue";
export default {
  name: "TraManQuan",
  components: {
    Navigation,
    Footer,
    CarouselCollection,
  },
  data() {
    return {
      listHeaderDetail: [
        "tra-man-quan.title-header",
        "tra-man-quan.content-header",
      ],
      listHeaderGaller: ["tra-man-quan.collection"],
      landingPageImgsBottom: [],
      playerVars: {
        controls: 0,
        rel: 1,
        mute: 1,
      },
      countPlay: 1,
    };
  },
  mounted() {
    this.initLoading();
    this.requestGetImagesGaller();
    this.loadVideoResource();
    this.createEventListenerForVideo();
    this.scrollTopPage();
  },
  computed: {
    player() {
      return this.$refs.traManQuanRef.player;
    },
  },
  destroyed() {
    this.destroyAnimation();
  },
  methods: {
    initLoading() {
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    loadVideoResource() {
      var video = this.$refs.video;
      if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(
          "https://magarimsluxuryspa.com/api/image/tra-man-quan/master.m3u8"
        );
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = "https://magarimsluxuryspa.com/api/image/test/master.m3u8";
      }
    },
    playingVideo() {
      var image = document.getElementById("background-image-video");
      var button = document.getElementById("play-video-button");
      image.style.display = "none";
      button.style.display = "none";
      var video = this.$refs.video;
      video.play();
    },
    createEventListenerForVideo() {
      var image = document.getElementById("background-image-video");
      var button = document.getElementById("play-video-button");
      var video = this.$refs.video;
      video.addEventListener("playing", function () {
        console.log("Video is now playing");
        image.style.display = "none";
        button.style.display = "none";
      });
      video.addEventListener("ended", function () {
        image.style.display = "block";
      });
      video.addEventListener("pause", function () {
        button.style.display = "block";
      });
    },
    requestGetImagesGaller() {
      const requestBody = {
        page: 1,
        limit: 1000,
        filter: {
          is_deleted: false,
          type: 1,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + IMAGES_LIST_API, requestBody)
        .then(async (res) => {
          if (res && res.data) {
            this.landingPageImgsBottom = await res.data[0].images;
            this.scrollTopPage();
            // setTimeout(() => {
            //   this.initAnimationViewDetailGallerImage();
            //   this.initAnimationGallerImages();
            // }, 1000);
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    initAnimationGallerImages() {
      $(".collection").slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 8000,
      });
    },
    initAnimationViewDetailGallerImage() {
      // ========================= Collection section =============================
      $(".collection").magnificPopup({
        type: "image",
        delegate: "a",
        fixedContentPos: false,
        gallery: {
          enabled: true,
        },
        removalDelay: 300,
        mainClass: "mfp-fade",
        zoom: {
          enabled: true,
          duration: 550,
          easing: "ease-in-out",
          opener: function (openerElement) {
            return openerElement.is("img")
              ? openerElement
              : openerElement.find("img");
          },
        },
      });

      // detect mouse direction use JavaScript
      var direction = "";
      var oldX = 0;
      var oldY = 0;
      var mousemovemethod = function (e) {
        if (e.pageX > oldX && e.pageY == oldY) {
          direction = "left-right";
        } else if (e.pageX == oldX && e.pageY > oldY) {
          direction = "top-bottom";
        } else if (e.pageX == oldX && e.pageY < oldY) {
          direction = "bottom-top";
        } else if (e.pageX < oldX && e.pageY == oldY) {
          direction = "right-left";
        }
        oldX = e.pageX;
        oldY = e.pageY;
      };
      document.addEventListener("mousemove", mousemovemethod);

      $(".collect-item").on("mouseenter", function () {
        let active = $(this);
        let currentDirec;
        // static variable : fix the direction is constantly changing
        if (typeof currentDirec == "undefined") {
          currentDirec = direction;
          document.removeEventListener("mousemove mousedown", mousemovemethod);
        }
        // add class fade in
        active.addClass("appear-" + currentDirec);
        active.find(".plus-icon").show(150);
        document.addEventListener("mousemove", mousemovemethod);
        // mouse leave -> add class fade out and remove class fade in
        active.on("mouseleave", function () {
          active.find(".plus-icon").hide(150);
          active
            .addClass("disappear-" + currentDirec)
            .one("webkitAnimationEnd", function () {
              active.removeClass("appear-" + currentDirec);
              active.removeClass("disappear-" + currentDirec);
            });
        });
      });
    },
    destroyAnimation() {
      $(".collection").slick("unslick");
    },
    scrollTopPage() {
      console.log("hello");
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    },
  },
};
</script>
<style scoped>
.tra-man-quan-container {
  background-image: url("../assets/flower1.svg");
  background-size: contain;
}
@media only screen and (max-width: 1440px) {
  .video-frame {
    height: 520px !important;
  }
}

@media only screen and (max-width: 767px) {
  .video-frame {
    height: 400px !important;
  }
}

@media only screen and (max-width: 480px) {
  .video-frame {
    height: 260px !important;
  }
}

@media only screen and (max-width: 375px) {
  .video-frame {
    height: 220px !important;
  }
}
.video-frame,
.video-frame video {
  width: 100%;
  height: 100%;
  /* height: 100vh; */
}

video,
.frame-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
}
.image-background-video {
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("/imgs/background-video-tra-man-quan.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.play-btn {
  cursor: pointer;
}
.tra-man-quan-detail-container {
  margin-top: 20px !important;
  width: 70%;
  margin: 0 auto;
  color: #72543a;
}
.title-tra-man-quan-detail {
  text-align: center;
  font-family: "sans-serif";
  font-size: 40px;
  font-weight: 700;
}
.tra-man-quan-detail-container {
  height: auto;
}
.title-galler {
  color: #72543a;
  text-align: center;
  font-family: "sans-serif";
  margin-bottom: 50px;
  position: relative;
  font-size: 30px;
  font-weight: 700;
}
.title-galler::after {
  position: absolute;
  left: 50%;
  margin-left: -74px;
  bottom: -35px;
  content: "";
  background: url("https://preview.hasthemes.com/plash-preview/plash/img/icon/title.png");
  width: 149px;
  height: 21px;
}
.title-tra-man-quan-wrap {
  position: relative;
  margin-bottom: 60px;
  margin-top: 20px;
}
.title-tra-man-quan-wrap::after {
  position: absolute;
  left: 50%;
  margin-left: -74px;
  content: "";
  background: url("https://preview.hasthemes.com/plash-preview/plash/img/icon/title.png");
  width: 149px;
  height: 21px;
}
.content-section {
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 50px;
}

.content-element {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-description,
.content-image {
  width: 50%;
  padding: 10px;
}

.content-image img {
  width: 100%;
  max-height: 300px;
  border-radius: 5px;
  object-fit: cover;
}
.content-description p {
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  font-family: "sans-serif";
}
.content-reverse {
  flex-direction: row-reverse;
}

@media (max-width: 769px) {
  .content-element {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .content-description,
  .content-image {
    width: 90%;
  }
  .tra-man-quan-detail-container {
    width: 95%;
  }
  .title-tra-man-quan-detail {
    font-size: 30px;
  }
}
@media (max-width: 480px) {
  .title-tra-man-quan-detail {
    font-size: 20px;
  }
}
</style>
