<template>
  <div>
    <div class="slide-image-big">
      <vueper-slides
        ref="vueperslides1"
        @slide="
          $refs.vueperslides2 &&
            $refs.vueperslides2.goToSlide($event.currentSlide.index + 1, {
              emit: false,
            })
        "
        :slide-ratio="1 / 4"
        :bullets="false"
        fixed-height="100%"
        :arrows="false"
      >
        <vueper-slide v-for="(item, i) in images" :key="i" :image="item" />
      </vueper-slides>
    </div>
    <div class="slide-image-small">
      <vueper-slides
        ref="vueperslides2"
        :slide-ratio="1 / 8"
        :bullets="false"
        :arrows="false"
        :gap="1"
        :dragging-distance="50"
        @slide="
          $refs.vueperslides1 &&
            $refs.vueperslides1.goToSlide($event.currentSlide.index, {
              emit: false,
            })
        "
        :visible-slides="10"
        fixed-height="100px"
        :infinite="true"
        :breakpoints="breakpoints"
      >
        <vueper-slide
          v-for="(item, i) in images"
          :key="i"
          :image="item"
          @click.native="
            $refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)
          "
        >
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
export default {
  name: "CarouselCollection",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      breakpoints: {
        1024: {
          visibleSlides: 8,
        },
        768: {
          visibleSlides: 6,
        },
        480: {
          visibleSlides: 4,
        },
      },
    };
  },
  props: {
    images: Array,
  },
};
</script>
<style>
.slide-image-small {
  margin-top: 20px;
}
.slide-image-big {
  height: 500px;
}
.active-slide {
  margin: 0px 2px;
}
.slide-image-big .vueperslide {
  background-size: contain !important;
  background-repeat: no-repeat;
}
.slide-image-big .vueperslides__parallax-wrapper:before,
.slide-image-big .vueperslides__parallax-wrapper::after {
  box-shadow: unset !important;
}

@media (max-width: 768px) {
  .slide-image-big {
    height: 380px;
  }
}

@media (max-width: 480px) {
  .slide-image-big {
    height: 240px;
  }
}
</style>
