import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { lang: "vi", isOpenLogin: false, isLogin: false },
  getters: {
    getLang(state) {
      return state.lang;
    },
    getStatusOpenLogin(state) {
      return state.isOpenLogin;
    },
    getStatusLogin(state) {
      return state.isLogin;
    },
  },
  mutations: {
    changeLang(state, newLang) {
      state.lang = newLang;
    },
    changeStatusOpenLogin(state, newStatusOpenLogin) {
      state.isOpenLogin = newStatusOpenLogin;
    },
    changeStatusLogin(state, newStatusLogin) {
      state.isLogin = newStatusLogin;
    },
  },
  actions: {
    handleChangeLang(context, newLang) {
      context.commit("changeLang", newLang);
    },
    handleChangeStatusOpenLogin(context, newStatusOpenLogin) {
      context.commit("changeStatusOpenLogin", newStatusOpenLogin);
    },
    handleChangeStatusLogin(context, newStatusLogin) {
      context.commit("changeStatusLogin", newStatusLogin);
    },
  },
  modules: {},
});
