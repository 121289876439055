<template>
  <footer>
    <div class="footer-cover"></div>
    <div class="footer-wrap container">
      <div class="footer-main">
        <img class="footer-logo" src="@/components/imgs/logo-footer.png" alt />
        <div class="footer-1">
          <div class="footer-title">MAGARIMS LUXURY SPA</div>
          <div class="footer-line">
            <fa-icon
              class="icon-custom"
              icon="fas fa-map-marker"
              beat
              color="#d3b673"
            ></fa-icon>
            85 P. Thụy Khuê, Tây Hồ, Hà Nội
          </div>
          <div class="footer-line">
            <fa-icon
              class="icon-custom"
              icon="fas fa-phone"
              beat
              color="#d3b673"
            ></fa-icon>
            0877701184
          </div>
          <div class="footer-line">
            <fa-icon
              class="icon-custom"
              icon="fas fa-envelope "
              beat
              color="#d3b673"
            ></fa-icon>
            magarimsluxuryspa@gmail.com
          </div>
          <div class="footer-line">
            <fa-icon
              class="icon-custom"
              icon="fas fa-calendar"
              beat
              color="#d3b673"
            ></fa-icon>
            9:00 AM to 9:00 PM from Monday to Sunday
          </div>
          <div class="footer-line social">
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100091319997195"
              class="icon-mxh"
            >
              <fa-icon
                icon="fab fa-facebook-square"
                beat
                color="#d3b673"
              ></fa-icon>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/Magarimsluxuryspa/?fbclid=IwAR0HQo1C5PTBoKIPuOPOLpA137JG7QG3MT7JL8FaQHfDgy0M9P6xnYzHHms"
              class="icon-mxh"
            >
              <fa-icon icon="fab fa-instagram" beat color="#d3b673"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="right">Magarims Luxury Spa</div>
        <div class="left">
          Copyright © 2023. Magarims Luxury Spa. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: rgba(51, 51, 51, 0.86);
}

.icon-mxh {
  margin-right: 30px;
}

footer {
  background: url("@/components/imgs/background-footer.jpg") no-repeat center;
  background-size: cover;
  padding-bottom: 50px;
  padding: 40px;
  position: relative;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  padding: 0 !important;
}

.footer-main {
  display: flex;
  justify-content: space-evenly;
}

.footer-wrap {
  z-index: 1;
  position: relative;
  padding-top: 40px !important;
}

.footer-logo {
  width: 24%;
  height: max-content;
  height: intrinsic;
}

footer img {
  width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

footer .footer-title {
  color: #d3b673;
  margin-bottom: 30px;
  font-style: normal !important;
  font-size: 34px;
  text-transform: uppercase;
}

.footer-title {
  font-family: BalooExtra;
  text-transform: uppercase;
  color: #d3b673;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.footer-line {
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
}
.icon-custom {
  font-size: 14px;
  margin-right: 10px !important;
}

.footer-2 {
  width: 18%;
}

ul {
  list-style: none outside;
  margin-left: 0;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 8px;
  list-style-type: none;
}

a {
  color: #ffffff !important;
  font-style: normal !important;
  font-size: 14px;
  text-decoration: none !important;
  border: none !important;
}

.footer-bottom {
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;
}

.footer-bottom .left {
  font-family: Muli;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 20px 4px;
  color: #ffffff;
}

.footer-bottom .right {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Muli;
  font-weight: 300;
  color: #ffffff;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* @media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
} */
/* 
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
} */

@media only screen and (max-width: 768px) {
  .footer-main {
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
  }

  .footer-main > * {
    width: 100%;
  }

  .footer-1 {
    text-align: left;
    width: 100% !important;
  }

  .footer-bottom {
    flex-wrap: wrap;
  }

  .footer-bottom > * {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.footer-1 {
  text-align: left;
  width: 40%;
}

.footer-1 fa-icon {
  color: #d3b673 !important;
}
</style>
