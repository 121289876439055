var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"slide-image-big"},[_c('vueper-slides',{ref:"vueperslides1",attrs:{"slide-ratio":1 / 4,"bullets":false,"fixed-height":"100%","arrows":false},on:{"slide":function($event){_vm.$refs.vueperslides2 &&
          _vm.$refs.vueperslides2.goToSlide($event.currentSlide.index + 1, {
            emit: false,
          })}}},_vm._l((_vm.images),function(item,i){return _c('vueper-slide',{key:i,attrs:{"image":item}})}),1)],1),_c('div',{staticClass:"slide-image-small"},[_c('vueper-slides',{ref:"vueperslides2",attrs:{"slide-ratio":1 / 8,"bullets":false,"arrows":false,"gap":1,"dragging-distance":50,"visible-slides":10,"fixed-height":"100px","infinite":true,"breakpoints":_vm.breakpoints},on:{"slide":function($event){_vm.$refs.vueperslides1 &&
          _vm.$refs.vueperslides1.goToSlide($event.currentSlide.index, {
            emit: false,
          })}}},_vm._l((_vm.images),function(item,i){return _c('vueper-slide',{key:i,attrs:{"image":item},nativeOn:{"click":function($event){_vm.$refs.vueperslides2 && _vm.$refs.vueperslides2.goToSlide(i)}}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }