<template>
  <div class="blogs-body">
    <div class="loader" id="loader">
      <div class="loader-inner">
        <img src="./imgs/loader.svg" alt="Loader" class="loader-img" />
      </div>
    </div>
    <Navigation />
    <div class="blogs-container">
      <div class="blog-item" v-for="item in previewBlogList" :key="item.index">
        <BlogCard
          :blogContent="item"
          @showDetail="handleShowBlogDetail(item)"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/common/Navigation.vue";
import Footer from "@/components/Footer.vue";
import BlogCard from "@/components/BlogCard.vue";
import { PAGES_API } from "@/constants/api-path";
import { PAGE_TYPE } from "@/constants/common";
import $ from "jquery";
import axios from "axios";
export default {
  name: "Blogs",
  components: {
    Navigation,
    Footer,
    BlogCard,
  },
  data() {
    return {
      previewBlogList: [],
    };
  },
  mounted() {
    this.initLoading();
    this.requestGetDataList(PAGE_TYPE.BLOG, false, true, {
      page: 1,
      limit: 30,
    });
  },
  methods: {
    initLoading() {
      $("#loader").fadeIn("fast");
      $("#loader").delay(1000).fadeOut("fast");
    },
    requestGetDataList(dataType, isDeleted, isParrentPage, paging) {
      const requestBody = {
        page: paging.page,
        limit: paging.limit,
        filter: {
          is_deleted: isDeleted,
          // is_parent_page: isParrentPage,
          page_type: dataType,
          lang: localStorage.getItem("lang") === "en" ? 1 : 0,
        },
        sort: {
          updated_at: 1,
        },
      };
      axios
        .post(this.$config.backendApi + PAGES_API, requestBody)
        .then((res) => {
          if (res && res.data) {
            this.buildDataList(res.data);
          }
        })
        .catch((err) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.error(err);
        });
    },
    buildDataList(data) {
      this.previewBlogList = [];
      data.forEach((element) => {
        const dataObject = {
          id: element.id,
          imgUrl: element.thumbnail,
          title: element.title,
          description: element.short_desc,
          slug: element.seo.slug,
        };
        const date = new Date(element.created_at);
        dataObject.day =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear();
        this.previewBlogList.push(dataObject);
      });
    },
    handleShowBlogDetail(item) {
      this.$router.push(`/page/detail/${item.id}`);
    },
    makeNotification(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
.blogs-body {
  background: #937056;
  background-image: url("../assets/flower.svg");
  background-size: contain;
}

.blogs-container {
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0px;
}

.blog-item {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .blogs-container {
    margin-top: -76vh;
  }
}

@media only screen and (min-width: 768px) {
  .blogs-container {
    margin-top: -75vh;
  }
}

@media only screen and (min-width: 820px) {
  .blogs-container {
    margin-top: -70vh;
    flex-direction: column;
  }
}

@media only screen and (min-width: 912px) {
  .blogs-container {
    margin-top: -60vh;
    flex-direction: column;
  }
}

@media only screen and (min-width: 992px) {
  .blogs-container {
    margin-top: -60vh;
    flex-direction: column;
  }
}

@media only screen and (min-width: 1200px) {
  .blogs-container {
    margin-top: -90vh;
    flex-direction: row;
  }
}
</style>
