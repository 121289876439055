<template>
  <b-modal
    id="modal-prevent-closing"
    ref="modal"
    v-model="isShowDialog"
    hide-footer
    modal-class="login-dialog"
    header-class="header-custom"
  >
    <div class="form-block mx-auto">
      <div class="text-center mb-5">
        <h3>Sign up to <strong>magarims</strong></h3>
      </div>
      <div>
        <div class="form-group first">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Full name"
            id="name"
            v-model="form.name"
          />
        </div>
        <div class="form-group first">
          <label for="username">Username</label>
          <input
            type="text"
            class="form-control"
            placeholder="your-email@gmail.com"
            id="username"
            v-model="form.email"
          />
        </div>
        <div class="form-group first mb-3">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            placeholder="Your Password"
            id="password"
            v-model="form.password"
          />
        </div>

        <div class="form-group first mb-3">
          <label for="confirmpassword">Confirm password</label>
          <input
            type="password"
            class="form-control"
            placeholder="Confirm Your Password"
            id="confirmpassword"
            v-model="form.password_confirm"
          />
        </div>

        <div class="d-sm-flex mb-5 align-items-center">
          <span class="ml-auto"
            ><a @click="openLoginDialog" class="forgot-pass sign-in"
              >Log in</a
            ></span
          >
        </div>

        <button @click="handleSingup()" class="btn btn-block btn-primary">
          Sign up
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { REGISTER_API } from "@/constants/api-path";
import axios from "axios";
export default {
  name: "RegisterFormDialog",
  data() {
    return {
      isShowDialog: false,
      form: {
        name: "",
        email: "",
        password: "",
        password_confirm: "",
      },
    };
  },
  methods: {
    openLoginDialog() {
      this.$refs["modal"].hide();
      this.$emit("openLoginDialog");
    },
    makeNotification(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    handleSingup() {
      axios.post(this.$config.backendApi + REGISTER_API, this.form).then(
        (res) => {
          if (res && res.data) {
            this.makeNotification(
              "success",
              this.$t("title.success"),
              this.$t("message.signup-success")
            );
            this.$refs["modal"].hide();
          }
        },
        (error) => {
          this.makeNotification(
            "danger",
            this.$t("title.error"),
            this.$t("message.request-error")
          );
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.sign-in {
  cursor: pointer;
}
p {
  color: #b3b3b3;
  font-weight: 300;
}

a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a:hover {
  text-decoration: none !important;
}

h2 {
  font-size: 20px;
}

.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}

.forgot-pass {
  position: relative;
  top: 2px;
  font-size: 14px;
}

.form-block {
  background: #fff;
  padding: 40px;
  max-width: 400px;
  padding-top: 0px;
}
@media (max-width: 767.98px) {
  .form-block {
    padding: 25px;
  }
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px;
}
.control .caption {
  position: relative;
  top: 0.2rem;
  color: #888;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 4px;
}

.control--radio .control__indicator {
  border-radius: 50%;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}

.control input:checked ~ .control__indicator {
  background: #fb771a;
}

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #fb8633;
}

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.9;
  pointer-events: none;
}

.control__indicator:after {
  font-family: "icomoon";
  content: "\e5ca";
  position: absolute;
  display: none;
  font-size: 16px;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff;
}

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  margin-top: -1px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

.control--checkbox input:disabled:checked ~ .control__indicator {
  background-color: #7e0cf5;
  opacity: 0.2;
}
</style>
