import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap-grid.css";
// import "bootstrap/dist/css/bootstrap-reboot.css";
import "bootstrap/dist/js/bootstrap.js";
// import "bootstrap/dist/js/bootstrap.bundle.js";
// import "bootstrap/dist/js/bootstrap.esm.js";
// import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/scss/bootstrap-grid.scss";
// import "bootstrap/scss/bootstrap-reboot.scss";
// import "bootstrap/scss/bootstrap-utilities.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./scss/style.scss";
import "./scss/queries.scss";
import "./scss/animation.scss";
import "slick-carousel/slick/slick.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "jquery-datetimepicker/build/jquery.datetimepicker.full.min.js";
import "jquery-datetimepicker/jquery.datetimepicker.css";
import "magnific-popup/dist/jquery.magnific-popup.min.js";
import "magnific-popup/dist/magnific-popup.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "vueperslides/dist/vueperslides.css";
// import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import $ from "jquery";
import CKEditor from "@ckeditor/ckeditor5-vue2";

import i18n from "./configs/i18n";
import VueI18n from "vue-i18n";
import VueYoutube from "vue-youtube";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
});

library.add(fas, fab);
Vue.component("fa-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(VueYoutube);
Vue.use(CKEditor);
// window.waypoint = require("waypoints/lib/noframework.waypoints");
// window.jQuery = window.$ = $;

fetch(process.env.BASE_URL + "config.json")
  .then((res) => res.json())
  .then((config) => {
    Vue.prototype.$config = config;
    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  });

// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
